import { PaginatedOrders } from '@lune-climate/lune'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Box from '@mui/material/Box'
import {
    Button,
    LoadingWrapper,
    MainLayoutContainer,
    NoResultsPlaceholder,
    Text,
} from 'lune-ui-lib'
import React, { useCallback, useEffect, useState } from 'react'

import { luneClient } from 'endpoints/api'
import useQuery from 'hooks/useQuery'
import useUserState from 'hooks/useUserState'
import OrdersList from 'views/Orders/OrdersList'

const Orders = () => {
    const limit = 10

    const offsetLinkId = useQuery('offset_link_id')
    const { userState } = useUserState()
    const [paginatedOrders, setPaginatedOrders] = useState<PaginatedOrders & { lastId?: string }>()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState<string>()

    const onLoadMore = useCallback(
        async (loadMore?: boolean) => {
            const result = await luneClient.listOrders(limit.toString(), page, offsetLinkId)

            if (!result.err) {
                const paginatedResult = result.unwrap()
                setLoading(false)
                setPaginatedOrders((prevValue) => ({
                    hasMore: paginatedResult.hasMore,
                    data: loadMore
                        ? [...(prevValue ? prevValue.data : []), ...paginatedResult.data]
                        : paginatedResult.data,
                }))
            } else {
                setPaginatedOrders({
                    hasMore: false,
                    data: [],
                })
            }
        },
        [page],
    )

    useEffect(() => {
        onLoadMore(!!page)
    }, [page, userState])

    useEffect(() => {
        setLoading(true)
        if (page) {
            setPage(undefined)
        }
    }, [userState])

    return (
        <MainLayoutContainer
            headerComponent={
                <Text data-testid={`all-orders-layout-title`} variant={'h4'}>
                    All orders
                </Text>
            }
        >
            <LoadingWrapper loading={loading}>
                {!paginatedOrders?.data?.length && !loading ? (
                    <Box sx={{ height: `554px` }}>
                        <NoResultsPlaceholder
                            message={
                                <Text variant={`h5`} color={`Grey900`}>
                                    No orders placed yet
                                </Text>
                            }
                        />
                    </Box>
                ) : (
                    <>
                        {paginatedOrders && <OrdersList paginatedOrders={paginatedOrders.data} />}
                        {paginatedOrders?.hasMore && (
                            <Button
                                variant={`outlined`}
                                leftIcon={<ArrowDownwardIcon />}
                                onClick={() =>
                                    setPage(
                                        paginatedOrders?.data![paginatedOrders.data!.length - 1]
                                            ?.id,
                                    )
                                }
                                sx={{
                                    mt: 9,
                                }}
                            >
                                Load more
                            </Button>
                        )}
                    </>
                )}
            </LoadingWrapper>
        </MainLayoutContainer>
    )
}

export default Orders
