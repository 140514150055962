import { Big } from 'big.js'
import getUserLocale from 'get-user-locale'
import moment from 'moment'

export const flattenObj = (input: Record<string, any>) => {
    const result: any = {}
    for (const key in input) {
        if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
            const temp = flattenObj(input[key])
            for (const j in temp) {
                result[key + ' - ' + j] = temp[j]
            }
        } else {
            result[key] = input[key]
        }
    }
    return result
}

export const capitalize = (s: string): string => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatTimestamp = (isoTimestamp: string): string => {
    return moment(isoTimestamp).format('LLL')
}

export const truncateStringWithoutCutWords = (str: string | undefined, maxLen: number) => {
    const separator = ` `
    if (str === undefined || str?.length <= maxLen) return str
    return str.substr(0, str.lastIndexOf(separator, maxLen))
}

export const maskify = (data: string, nrVisibleChars: number = 8) =>
    data.slice(0, -nrVisibleChars).replace(/./g, '∗') + data.slice(-nrVisibleChars)

export const formatNumbers = (number: number | string) => {
    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale)
    } catch (e) {
        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat. 
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale)
    }

    try {
        return numberFormat.format(Big(number).toNumber())
    } catch (e) {}

    return number
}

export const formatToCurrency = (number: number | string, currency: string) => {
    if (!currency) {
        return number.toString()
    }

    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol',
        })
    } catch (e) {
        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat. 
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
        })
    }

    return numberFormat.format(Big(number).toNumber())
}

/**
 * This takes a bundle selection object and returns the sum of the percentages as Big
 * eg { 'bundle-id-1': 50, 'bundle-id-2': 50 } => Big(100)
 * @param bundleSelection
 * @param volume
 */
export const calculateBundleSelectionTotalSum = (
    bundleSelection: Record<string, number>,
    volume?: boolean,
): Big =>
    Object.values(bundleSelection).reduce((sum, currentAllocation) => {
        try {
            const amountAsBig = Big(currentAllocation)
            if (!!volume || (amountAsBig.gte(Big(0)) && amountAsBig.lte(Big(100)))) {
                return sum.add(amountAsBig)
            }
        } catch {}
        return sum
    }, Big(0))
