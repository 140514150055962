import React from 'react'

import styles from './BundleMobileAddRibbonWrapper.module.css'

const BundleMobileAddRibbonWrapper = ({
    children,
    backgroundImage,
    className,
}: {
    children: React.ReactNode
    backgroundImage: string
    className?: string
}) => (
    <div className={`${styles.OuterContainer} ${className || ''}`}>
        <div className={styles.Container} style={{ backgroundImage }}>
            {children}
        </div>
    </div>
)

export default BundleMobileAddRibbonWrapper
