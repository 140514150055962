import { CloudProvider, CompanyEstimateRequest } from '@lune-climate/lune'
import postalCodes from 'postal-codes-js'
import React from 'react'
import { Field } from 'react-final-form'

import { CalculatorIds } from 'apps/CalculatorApp/CalculatorSelectionMenu'
import Wizard from 'apps/CalculatorApp/CalculatorWizardForm'
import CountryPicker from 'componentsOld/CountryPicker'
import {
    FormInput,
    FormMoneyAmountInput,
    FormNumberInput,
    FormNumberInputAsString,
    FormSwitch,
} from 'componentsOld/FieldComponents'
import FormField from 'componentsOld/FormField'
import Select from 'componentsOld/Select'
import SelectableButtonGroup from 'componentsOld/SelectableButtonGroup'
import { luneClient } from 'endpoints/api'

const BACKGROUND_IMAGE_URL = 'https://assets.lune.co/calculator/Company+Emissions%402x.jpg'

const onSubmit = async (values: CompanyEstimateRequest) =>
    luneClient.createCompanyEstimate(values).then((r) => r.unwrap())

const initialValues = {
    officeArea: {
        amount: `0`,
        unit: 'square_meters' as any,
    },
    countryCode: `GBR`,
    postcode: ``,

    employees: 0,
    remoteEmployeesPercentage: 0,

    electricityConsumption: 0,
    greenElectricityUsed: false,
    gasConsumption: 0,

    companyCars: 0,
    averageCarDistanceTravelled: {
        amount: `0`,
        unit: `km` as any,
    },
    employeesUsingPublicTransport: 0,

    shortFlights: 0,
    mediumFlights: 0,
    longFlights: 0,
    firstOrBusinessClassPercentage: 0,

    foodAndDrinksExpenses: {
        value: `0`,
        currency: `GBP`,
    },
    vegetarianAndVeganPercentage: 0,

    electronicDeviceExpenses: {
        value: `0`,
        currency: `GBP`,
    },
    garbage: 0,
    recycledGarbagePercentage: 0,
    tech: {
        cloud: {
            cost: {
                value: `0`,
                currency: `GBP`,
            },
            provider: CloudProvider.AWS,
        },
        onPremise: {
            electricityCost: {
                value: `0`,
                currency: `GBP`,
            },
        },
    },
}

const FormCloudServicesProviderPicker = ({ input: { onChange, value } }: any) => {
    const options = Object.entries(CloudProvider).map(([key, value]) => ({
        label: key,
        value,
    }))
    return (
        <Select
            value={{
                label: options.find((o) => o.value === value)?.label!,
                value,
            }}
            onChange={(e: any) => onChange(e?.value)}
            options={options}
        />
    )
}

const required = (value: any) => (value ? undefined : 'Required')

const isWithin100Percent = (value: any) =>
    value >= 0 && value <= 100 ? undefined : 'Must be between 0 and 100'

const CompanyFootprintCalculator = () => {
    return (
        <Wizard<CompanyEstimateRequest>
            initialValues={initialValues}
            onSubmit={onSubmit}
            title={'Company Carbon Footprint'}
            id={CalculatorIds.COMPANY_FOOTPRINT}
        >
            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={'Location'}
                progressBarLabel={'Location'}
            >
                {(values: CompanyEstimateRequest) => (
                    <>
                        <Field
                            name={'countryCode'}
                            validate={required}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <CountryPicker
                                    error={meta.touched && meta.error}
                                    value={value}
                                    onChange={(e) => {
                                        if (e) onChange(e.value)
                                    }}
                                />
                            )}
                        />
                        <FormField
                            label={'City (Optional)'}
                            component={<Field name="city" component={FormInput} />}
                        />
                        {values.countryCode && (
                            <FormField
                                label={'Post code (Optional)'}
                                component={
                                    <Field
                                        name="postcode"
                                        component={FormInput}
                                        validate={(value) => {
                                            if (!value) return
                                            const postCodeValidationRes = postalCodes.validate(
                                                values.countryCode,
                                                value,
                                            )
                                            if (postCodeValidationRes !== true)
                                                return postCodeValidationRes
                                        }}
                                    />
                                }
                            />
                        )}
                    </>
                )}
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Office Area`}
                progressBarLabel={`Office Area`}
            >
                <FormField
                    label={'Office area amount'}
                    component={
                        <Field
                            validate={required}
                            name={'officeArea.amount'}
                            component={FormNumberInputAsString}
                            type="text"
                        />
                    }
                />
                <FormField
                    label={'Office area unit'}
                    component={
                        <Field
                            name={'officeArea.unit'}
                            component={({ input: { onChange, value } }: any) => {
                                return (
                                    <SelectableButtonGroup
                                        options={[
                                            {
                                                label: `m²`,
                                                value: `square_meters`,
                                            },
                                            {
                                                label: `ft²`,
                                                value: `square_feet`,
                                            },
                                        ]}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    }
                />
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Employees`}
                progressBarLabel={`Employees`}
            >
                <FormField
                    label={'Number of employees'}
                    component={
                        <Field
                            validate={(v) => {
                                if (!v || v < 1) {
                                    return `This should be a positive number`
                                }
                            }}
                            name={'employees'}
                            component={FormNumberInput}
                            type="text"
                        />
                    }
                />
                <FormField
                    label={'% of all employees working remotely'}
                    component={
                        <Field
                            name={'remoteEmployeesPercentage'}
                            component={FormNumberInput}
                            validate={isWithin100Percent}
                            type="text"
                        />
                    }
                />
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Electricity and Heating`}
                progressBarLabel={`Electricity and Heating`}
            >
                <FormField
                    label={'Electricity consumption (kWh)'}
                    component={
                        <Field
                            name={'electricityConsumption'}
                            component={FormNumberInput}
                            type="text"
                        />
                    }
                />
                <FormField
                    label={'We use green energy'}
                    component={<Field name={'greenElectricityUsed'} component={FormSwitch} />}
                />
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Land Travel`}
                progressBarLabel={`Land Travel`}
            >
                {(values: CompanyEstimateRequest) => (
                    <>
                        <FormField
                            label={'Number of employees commuting by public transport'}
                            component={
                                <Field
                                    name={'employeesUsingPublicTransport'}
                                    component={FormNumberInput}
                                    type="text"
                                />
                            }
                        />
                        <FormField
                            label={'Number of owned company cars'}
                            component={
                                <Field
                                    name={'companyCars'}
                                    component={FormNumberInput}
                                    type="text"
                                />
                            }
                        />
                        {values.companyCars > 0 && (
                            <>
                                <FormField
                                    label={'Average distance travelled per period'}
                                    component={
                                        <Field
                                            name={'averageCarDistanceTravelled.amount'}
                                            component={FormNumberInputAsString}
                                            type="text"
                                        />
                                    }
                                />
                                <FormField
                                    label={'Average Distance Units'}
                                    component={
                                        <Field
                                            name={'averageCarDistanceTravelled.unit'}
                                            component={({ input: { onChange, value } }: any) => {
                                                return (
                                                    <SelectableButtonGroup
                                                        value={value}
                                                        onChange={onChange}
                                                        options={[
                                                            { value: `km`, label: 'Kilometers' },
                                                            { value: `mi`, label: 'Miles' },
                                                        ]}
                                                    />
                                                )
                                            }}
                                            type="text"
                                        />
                                    }
                                />
                            </>
                        )}
                    </>
                )}
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Flights`}
                subtitle={
                    <div>
                        <div>How many flights do your employees take per period?</div>
                        <div>(A round trip counts as two flights)</div>
                    </div>
                }
                progressBarLabel={`Air Travel`}
            >
                {(values: CompanyEstimateRequest) => (
                    <>
                        <FormField
                            label={'Short flights (up to 3 hours)'}
                            component={
                                <Field
                                    name={'shortFlights'}
                                    component={FormNumberInput}
                                    type="text"
                                />
                            }
                        />
                        <FormField
                            label={'Medium flight (up to 6 hours)'}
                            component={
                                <Field
                                    name={'mediumFlights'}
                                    component={FormNumberInput}
                                    type="text"
                                />
                            }
                        />
                        <FormField
                            label={'Long flights (over 6 hours)'}
                            component={
                                <Field
                                    name={'longFlights'}
                                    component={FormNumberInput}
                                    type="text"
                                />
                            }
                        />
                        {!!(values.shortFlights || values.mediumFlights || values.longFlights) && (
                            <FormField
                                label={'% of First/Business class travel'}
                                component={
                                    <Field
                                        name={'firstOrBusinessClassPercentage'}
                                        component={FormNumberInput}
                                        validate={isWithin100Percent}
                                        type="text"
                                    />
                                }
                            />
                        )}
                    </>
                )}
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Food & Drink`}
                progressBarLabel={`Food & Drink`}
                subtitle={'How much do you spend  on food and drinks?'}
            >
                {(values: CompanyEstimateRequest) => (
                    <>
                        <FormField
                            label={'Amount'}
                            component={
                                <FormMoneyAmountInput
                                    formAmountName={`foodAndDrinksExpenses.value`}
                                    formCurrencyName={`foodAndDrinksExpenses.currency`}
                                />
                            }
                        />
                        {values.foodAndDrinksExpenses && (
                            <FormField
                                label={'% of vegan/vegetarian meals'}
                                component={
                                    <Field
                                        name={'vegetarianAndVeganPercentage'}
                                        component={FormNumberInput}
                                        validate={isWithin100Percent}
                                        type="text"
                                    />
                                }
                            />
                        )}
                    </>
                )}
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`New devices`}
                progressBarLabel={`Material`}
                subtitle={'How much do you spend on new devices per year?'}
            >
                <FormField
                    label={'Amount'}
                    component={
                        <FormMoneyAmountInput
                            formAmountName={`electronicDeviceExpenses.value`}
                            formCurrencyName={`electronicDeviceExpenses.currency`}
                        />
                    }
                />
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Waste`}
                progressBarLabel={`Waste`}
                subtitle={'Garbage waste per period?'}
            >
                <FormField
                    label={'Amount (kg)'}
                    component={<Field name={'garbage'} component={FormNumberInput} type="text" />}
                />
                <FormField
                    label={'% of recycled waste'}
                    component={
                        <Field
                            name={'recycledGarbagePercentage'}
                            component={FormNumberInput}
                            validate={isWithin100Percent}
                            type="text"
                        />
                    }
                />
            </Wizard.Page>

            <Wizard.Page
                backgroundImageSrc={BACKGROUND_IMAGE_URL}
                title={`Tech`}
                progressBarLabel={`Tech`}
            >
                <>
                    <FormField
                        label={'Cloud service infrastructure cost'}
                        component={
                            <FormMoneyAmountInput
                                formAmountName={`tech.cloud.cost.value`}
                                formCurrencyName={`tech.cloud.cost.currency`}
                            />
                        }
                    />
                    <FormField
                        label={'Provider'}
                        component={
                            <Field
                                name={'tech.cloud.provider'}
                                component={FormCloudServicesProviderPicker}
                                type="text"
                            />
                        }
                    />
                    <FormField
                        label={'On-premise infrastructure cost'}
                        component={
                            <FormMoneyAmountInput
                                formAmountName={`tech.onPremise.electricityCost.value`}
                                formCurrencyName={`tech.onPremise.electricityCost.currency`}
                            />
                        }
                    />
                </>
            </Wizard.Page>
        </Wizard>
    )
}

export default CompanyFootprintCalculator
