import {
    BundlePercentage,
    MassUnit,
    OrderQuoteBase,
    OrderQuoteByQuantityRequest,
} from '@lune-climate/lune'
import { Big } from 'big.js'
import mixpanel from 'mixpanel-browser'
import { useSnackbar } from 'notistack'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { debounce } from 'throttle-debounce'

import { luneClient } from 'endpoints/api'
import useAccounts from 'hooks/useAccounts'
import useBundles from 'hooks/useBundles'
import useIndividualAccountBundles from 'hooks/useIndividualAccountBundles'
import { IOrderByMassPayload, IOrderByValuePayload, OrderType } from 'models/order'
import { mapErrorsToMessage } from 'SnackbarMessages'
import OrderSummary from 'views/BuyOffsets/OrderSummary'
import PlaceOrder from 'views/BuyOffsets/PlaceOrder'
import { calculateAveragePrice, CUSTOM_MIX_ID } from 'views/Settings/BundlePicker/BundlePicker'

const T_TO_KG = Big(1000)
const KG_TO_T = Big(0.001)

export interface BuyOffsetProps {
    amount: string
    orderType: string
    showBundlePicker: boolean
    showOrderSummary: boolean
    truncateToTonnes: boolean
    allocation: Record<string, number>
    customBundleSelection?: Record<string, number>
    bundleMixId: string
    quote?: OrderQuoteBase
    error?: boolean
    averageBundlePrice: Big
    payloadByMass?: IOrderByMassPayload
    payloadByValue?: IOrderByValuePayload
}

export interface BuyOffsetComponent {
    buyOffsetsState: {
        buyOffsetsProps: BuyOffsetProps
        setBuyOffsetsProps: Dispatch<SetStateAction<BuyOffsetProps>>
    }
}

const BuyOffsets = () => {
    const client = useQueryClient()
    const [buyOffsetsProps, setBuyOffsetsProps] = useState<BuyOffsetProps>({
        amount: '',
        orderType: OrderType.QUANTITY,
        showBundlePicker: false,
        showOrderSummary: false,
        truncateToTonnes: true,
        allocation: {},
        customBundleSelection: {},
        bundleMixId: CUSTOM_MIX_ID,
        quote: undefined,
        error: false,
        averageBundlePrice: Big(0),
        payloadByMass: undefined,
        payloadByValue: undefined,
    })

    const { loadBundles } = useIndividualAccountBundles()
    const { activeAccount } = useAccounts()
    const { bundles } = useBundles()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const {
        amount,
        orderType,
        showOrderSummary,
        allocation,
        error,
        truncateToTonnes,
    } = buyOffsetsProps

    useEffect(() => {
        setBuyOffsetsProps((prevState) => {
            return {
                ...prevState,
                averageBundlePrice: calculateAveragePrice(bundles, allocation!),
            }
        })
    }, [buyOffsetsProps?.allocation, bundles])

    useEffect(() => {
        if (!activeAccount) {
            return
        }
        loadBundles(activeAccount!.id).then((bundleSelection) =>
            setBuyOffsetsProps((prevState) => {
                return {
                    ...prevState,
                    customBundleSelection: bundleSelection,
                }
            }),
        )
        setBuyOffsetsProps((prevState) => {
            return {
                ...prevState,
                bundleMixId: activeAccount?.bundleMixId || CUSTOM_MIX_ID,
            }
        })
    }, [activeAccount])

    const calculateQuoteDebouncedByMass = useMemo(
        () =>
            debounce(250, false, async (payload: IOrderByMassPayload) => {
                const r = await client.fetchQuery(JSON.stringify(payload), () =>
                    luneClient.getOrderQuoteByMass(payload as OrderQuoteByQuantityRequest),
                )
                if (r.ok) {
                    const res = r.val
                    setBuyOffsetsProps((prevState) => {
                        return {
                            ...prevState,
                            error: false,
                            quote: res,
                        }
                    })
                } else {
                    const errors = r.val.errors?.errors
                    snackbar(mapErrorsToMessage(errors))
                    setBuyOffsetsProps((prevState) => {
                        return {
                            ...prevState,
                            error: true,
                        }
                    })
                }
                mixpanel.track('Quote', {
                    type: 'by-mass',
                    amount: Big(payload.mass.amount).mul(KG_TO_T).toNumber(),
                    unit: 't',
                })
            }),
        [],
    )

    const calculateQuoteDebouncedByValue = useMemo(
        () =>
            debounce(250, false, async (payload: IOrderByValuePayload) => {
                const r = await client.fetchQuery(JSON.stringify(payload), () =>
                    luneClient.getOrderQuoteByValue(payload),
                )

                if (r.ok) {
                    const res = r.val
                    setBuyOffsetsProps((prevState) => {
                        return {
                            ...prevState,
                            error: false,
                            quote: res,
                        }
                    })
                } else {
                    const errors = r.val.errors?.errors
                    snackbar(mapErrorsToMessage(errors))
                    setBuyOffsetsProps((prevState) => {
                        return {
                            ...prevState,
                            error: true,
                        }
                    })
                }

                mixpanel.track('Quote', {
                    type: 'by-value',
                    value: Big(payload.value).toNumber(),
                })
            }),
        [],
    )

    const orderPayloadByMass = useMemo<IOrderByMassPayload>((): IOrderByMassPayload => {
        const payload = {
            mass: {
                amount: Big(amount || 0)
                    .mul(T_TO_KG)
                    .toString(),
                unit: 'kg',
            },
            bundleSelection: Object.keys(allocation).map(
                (id): BundlePercentage => ({
                    bundleId: id,
                    percentage: allocation[id],
                }),
            ),
        }

        const payloadByMass = truncateToTonnes ? { ...payload, quantityTrunc: MassUnit.T } : payload
        setBuyOffsetsProps((prevState) => {
            return {
                ...prevState,
                payloadByMass: payloadByMass,
            }
        })
        return payloadByMass
    }, [amount, allocation, truncateToTonnes])

    const orderPayloadByValue = useMemo<IOrderByValuePayload>((): IOrderByValuePayload => {
        const payload = {
            value: Big(amount || 0).toString(),
            bundleSelection: Object.keys(allocation).map((id) => ({
                bundleId: id,
                percentage: allocation[id],
            })),
        }
        const payloadByValue = truncateToTonnes
            ? { ...payload, quantityTrunc: MassUnit.T }
            : payload
        setBuyOffsetsProps((prevState) => {
            return {
                ...prevState,
                payloadByValue: payloadByValue,
            }
        })
        return payloadByValue
    }, [amount, allocation, truncateToTonnes])

    useEffect(() => {
        if (amount && !error) {
            orderType === OrderType.VALUE
                ? calculateQuoteDebouncedByValue(orderPayloadByValue)
                : calculateQuoteDebouncedByMass(orderPayloadByMass)
        }
    }, [orderPayloadByMass, orderPayloadByValue, amount, orderType])

    return showOrderSummary ? (
        <OrderSummary buyOffsetsState={{ buyOffsetsProps, setBuyOffsetsProps }} />
    ) : (
        <PlaceOrder buyOffsetsState={{ buyOffsetsProps, setBuyOffsetsProps }} />
    )
}

export default BuyOffsets
