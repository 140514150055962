import CircleButton from 'componentsOld/CircleButton'

import styles from './AddBundleRibbon.module.css'

const AddBundleRibbon = ({
    bundleName,
    bundleId,
    onSelect,
}: {
    bundleName: string
    bundleId: string
    onSelect: (bundleId: string) => void
}) => {
    return (
        <div className={styles.AddRibbonOuter}>
            <div className={styles.AddRibbon}>
                <div className={styles.RibbonTitle}>{bundleName}</div>
                <div className={styles.RibbonAddButtonContainer}>
                    <CircleButton
                        className={styles.RibbonAddButton}
                        active={false}
                        ctaTextActivate={`Select`}
                        onClick={() => {
                            if (bundleId) {
                                onSelect(bundleId)
                            }
                        }}
                    />
                </div>
                <div className={styles.RibbonBackground} />
            </div>
        </div>
    )
}

export default AddBundleRibbon
