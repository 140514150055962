import React from 'react'

import './GradientBar.css'

interface IGradientBarProps {
    height: string
    ref?: React.RefObject<any>
}

export default function GradientBar({ height, ref }: IGradientBarProps) {
    return <div ref={ref} style={{ height }} className="gradient-bar" id={`gradientBar`} />
}
