import React from 'react'

import Button from 'componentsOld/Button'

import styles from './CalculatorButton.module.css'

const CalculatorButton = ({
    text,
    type,
    disabled,
    onClick,
    className,
    ...rest
}: {
    className?: string
    onClick?: () => void
    text: React.ReactNode
    type?: 'submit'
    disabled?: boolean
}) => {
    return (
        <div className={`${styles.BtnWrapper} ${className}`} onClick={onClick}>
            <Button {...rest} type={type} className={styles.Btn} disabled={disabled}>
                {text}
            </Button>
        </div>
    )
}

export default CalculatorButton
