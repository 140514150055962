import { MonetaryAmount } from '@lune-climate/lune'

export enum ActivitySource {
    ADMIN_CREDIT = 'admin_credit',
    ADMIN_DEBIT = 'admin_debit',
    DEPOSIT = 'deposit',
    ORDER_RECEIVED = 'order_received',
    ORDER_PLACED = 'order_placed',
    ORDER_RETIRING = 'order_retiring',
    ORDER_ALLOCATION_RETIRED = 'order_allocation_retired',
    ORDER_PAID = 'order_paid',
    ORDER_COMPLETE = 'order_complete',
    ORDER_CANCELLED = 'order_cancelled',
    ORDER_FAILED = 'order_failed',
}

export interface IActivity {
    id: string
    createdAt: string
    accountId: string
    currency: string
    source: string
    balance: MonetaryAmount
    balanceDelta: MonetaryAmount
    balanceOutstanding: MonetaryAmount
    balanceOutstandingDelta: MonetaryAmount
    quantity?: string
    orderId?: string
    allocationId?: string
    projectId?: string
    projectName?: string
    commission?: MonetaryAmount
}
