import {
    BundlePercentage,
    CreateOrderByQuantityRequest,
    MassUnit,
    OrderQuoteByQuantity,
    OrderQuoteByQuantityRequest,
} from '@lune-climate/lune'
import { Big } from 'big.js'
import mixpanel from 'mixpanel-browser'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'

import Button from 'componentsOld/Button'
import Input from 'componentsOld/Input'
import InputLabel from 'componentsOld/InputLabel'
import LayoutContainer from 'componentsOld/LayoutContainer'
import LayoutDivider from 'componentsOld/LayoutDivider/LayoutDivider'
import SelectedBundlesList from 'componentsOld/SelectedBundlesList'
import { luneClient } from 'endpoints/api'
import useAccountCurrency from 'hooks/useAccountCurrency'
import { IOrderByMassPayload } from 'models/order'
import BundleSelectionManager from 'viewsOld/BundleSelectionManager'
import ConfirmOrder from 'viewsOld/ConfirmOrder'

import styles from './OrderByQuantity.module.css'

const T_TO_KG = Big(1000)
const KG_TO_T = Big(0.001)

export default function OrderByQuantity({ defaultQuantity }: { defaultQuantity?: string }) {
    const toCurrency = useAccountCurrency()
    const [confirm, setConfirm] = useState<boolean>(false)
    const [quote, setQuote] = useState<OrderQuoteByQuantity>()
    const [allocation, setAllocation] = useState<Record<string, number>>({})
    const [quantity, setQuantity] = useState<string>(defaultQuantity || '')
    const [tonneTrunc, setTonneTrunc] = useState<boolean>(true)
    const [errors, setErrors] = useState<string>()

    const { estimatedTotalCost, estimatedCommission } = quote || {}

    const orderPayload = useMemo<IOrderByMassPayload>((): IOrderByMassPayload => {
        const payload = {
            mass: {
                amount: Big(quantity || 0)
                    .mul(T_TO_KG)
                    .toString(),
                unit: 'kg',
            },
            bundleSelection: Object.keys(allocation).map(
                (id): BundlePercentage => ({
                    bundleId: id,
                    percentage: allocation[id],
                }),
            ),
        }

        if (!tonneTrunc) {
            return payload
        }

        return {
            ...payload,
            quantityTrunc: MassUnit.T,
        }
    }, [quantity, tonneTrunc, allocation])

    const calculateQuoteDebounced = useMemo(
        () =>
            debounce(250, false, (payload: IOrderByMassPayload) => {
                luneClient.getOrderQuoteByMass(payload as OrderQuoteByQuantityRequest).then((r) => {
                    setQuote(r.unwrap())
                    mixpanel.track('Quote', {
                        type: 'by-mass',
                        amount: Big(payload.mass.amount).mul(KG_TO_T).toNumber(),
                        unit: 't',
                    })
                })
            }),
        [],
    )

    useEffect(() => {
        if (quantity && !errors?.length) {
            calculateQuoteDebounced(orderPayload)
        }
    }, [quantity, tonneTrunc, orderPayload])

    const onQuantityChange = useCallback((e) => {
        const quantity = e?.target?.value
        if (!quantity) {
            setQuantity(``)
        } else {
            try {
                Big(quantity)
                setQuantity(quantity)
            } catch {}
        }
    }, [])

    const onTonneTruncChange = useCallback((e) => {
        const checked = e?.target?.checked
        setTonneTrunc(checked)
    }, [])

    return (
        <div className={styles.OuterContainer}>
            <div className={styles.Container}>
                {confirm && quote ? (
                    <ConfirmOrder
                        quote={quote}
                        placeOrder={async () =>
                            luneClient
                                .createOrderByMass(orderPayload as CreateOrderByQuantityRequest)
                                .then((r) => r.unwrap())
                        }
                        cancel={() => setConfirm(false)}
                    />
                ) : (
                    <>
                        <LayoutContainer title={`Order Details`}>
                            <div className="order-input-section">
                                <InputLabel for="quantity">Quantity (tCO2)</InputLabel>
                                <Input
                                    data-testid={'quantity-input'}
                                    value={quantity}
                                    onChange={onQuantityChange}
                                />
                                {quantity !== `0` && quantity && quote && (
                                    <div className="order-input-group">
                                        <div className="order-input-section">
                                            <InputLabel>Estimated Cost</InputLabel>
                                            <h2 data-testid={'estimated-cost'}>
                                                {toCurrency?.(estimatedTotalCost)}
                                            </h2>
                                        </div>
                                        <div className="order-input-section">
                                            <p>
                                                Includes Lune's fee{' '}
                                                {toCurrency?.(estimatedCommission)}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="order-input-section">
                                <InputLabel for="tonneTrunc">
                                    Round down
                                    <br />
                                    to nearest tonne
                                </InputLabel>
                                <Input
                                    data-testid={'tonneTrunc-input'}
                                    id="tonneTrunc"
                                    type="checkbox"
                                    checked={tonneTrunc}
                                    onChange={onTonneTruncChange}
                                />
                            </div>
                        </LayoutContainer>
                        <LayoutDivider />
                        <LayoutContainer title={`Bundle Selection`}>
                            <BundleSelectionManager onChange={setAllocation} onError={setErrors} />
                        </LayoutContainer>
                        {quantity && quantity !== `0` && quote && quote.bundles.length > 0 && (
                            <>
                                <LayoutContainer title={`Current Order Details`}>
                                    <SelectedBundlesList bundles={quote.bundles} />
                                </LayoutContainer>
                            </>
                        )}
                        {quantity && quantity !== `0` && (
                            <div className={styles.Controls}>
                                <Button
                                    disabled={!!errors || !quantity || quantity === `0`}
                                    onClick={() => setConfirm(true)}
                                    data-testid="proceed-button"
                                >
                                    Proceed
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
