import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactDOM from 'react-dom'
import { usePopperTooltip } from 'react-popper-tooltip'

import styles from './TooltipInfo.module.css'

const TooltipInfo = ({
    description,
    iconClassName,
}: {
    description: string | React.ReactNode
    iconClassName?: string
}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({ trigger: 'click', closeOnOutsideClick: true })

    return (
        <>
            {description && (
                <div ref={setTriggerRef} className={`${iconClassName || styles.Info}`}>
                    <FontAwesomeIcon icon={faInfoCircle} className={styles.InfoHover} />
                </div>
            )}
            {visible &&
                ReactDOM.createPortal(
                    <div
                        ref={setTooltipRef}
                        {...getTooltipProps({ className: 'tooltip-container' })}
                    >
                        <div style={{ width: '300px' }}>{description}</div>
                        <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                    </div>,
                    document.body,
                )}
        </>
    )
}

export default TooltipInfo
