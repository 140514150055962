import Activity from 'viewsOld/Activity'

const ActivityPage = () => {
    return (
        <>
            <h3>Activity</h3>
            <Activity />
        </>
    )
}

export default ActivityPage
