import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import React, { useEffect, useMemo, useState } from 'react'

import FormField from 'componentsOld/FormField'

import styles from './CardSection.module.css'

interface ICardSectionProps {
    onChange?: (card: StripeCardNumberElement | undefined) => void
}

const CardSection = ({ onChange }: ICardSectionProps) => {
    const elements = useElements()
    const [validCardNumber, setValidCardNumber] = useState<boolean>(false)
    const [validExpiry, setValidExpiry] = useState<boolean>(false)
    const [validCvc, setValidCvc] = useState<boolean>(false)

    const isValid = useMemo(() => {
        return validCardNumber && validExpiry && validCvc
    }, [validCardNumber, validExpiry, validCvc])

    useEffect(() => {
        if (onChange) {
            // NOTE: it is important that <Confirm /> is never unmounted for CardNumberElement to always reference to the same DOM element.
            // Failure to do the above will yield a null card. This is why I reduced the scope of LoadingWrapper.
            const card = elements?.getElement(CardNumberElement)
            if (isValid) {
                onChange(card!)
            }
        }
    }, [validCardNumber, validExpiry, validCvc])

    return (
        <>
            <FormField
                label={'Card number'}
                component={
                    <CardNumberElement
                        className="input"
                        onChange={(e) => setValidCardNumber(!!e.complete)}
                    />
                }
            />
            <div className={styles.ExpiryAndCvcContainer}>
                <FormField
                    className={styles.Expiry}
                    label={'Expiry Date'}
                    component={
                        <CardExpiryElement
                            className="input"
                            onChange={(e) => setValidExpiry(!!e.complete)}
                        />
                    }
                />
                <FormField
                    className={styles.Csv}
                    label={'CSV / CVV'}
                    component={
                        <CardCvcElement
                            className="input"
                            onChange={(e) => setValidCvc(!!e.complete)}
                        />
                    }
                />
                <div className="clearfix" />
            </div>
        </>
    )
}

export default CardSection
