import { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { LinksContext, LOCAL_STORAGE_SELECTED_BUNDLE_KEY } from 'apps/LinksApp/LinksApp'
import useQuery from 'hooks/useQuery'
import BundleDetails from 'viewsOld/ProjectExplorer/BundleDetails'
import { SwitchViews } from 'viewsOld/ProjectExplorer/BundleDetails/SwitchButtonGroup'

const LinksBundleDetails = () => {
    const history = useHistory()
    const { id: offsetLinkId, view } = useParams<{ id: string; view: SwitchViews }>()
    const { data, setSelectedBundleIds } = useContext(LinksContext)
    const bundleId = useQuery('bundleId')

    return (
        <BundleDetails
            bundle={data?.bundles?.find((b) => b.id === bundleId)!}
            onSelect={(selected: string) => {
                setSelectedBundleIds([selected])
                localStorage.setItem(LOCAL_STORAGE_SELECTED_BUNDLE_KEY, JSON.stringify([selected]))
                history.push(`/${offsetLinkId}/confirm`)
            }}
            view={view}
            onSwitchView={(view) => {
                history.push({
                    pathname: `/${offsetLinkId}/explore/${view}`,
                    search: `?bundleId=${bundleId}`,
                })
            }}
        />
    )
}

export default LinksBundleDetails
