import { useContext, useMemo } from 'react'

import { LinksContext } from 'apps/LinksApp/LinksApp'
import ThankYou from 'viewsOld/ProjectExplorer/ThankYou'

const LinksThankYou = () => {
    const { data, selectedBundleIds, mainContentWidth } = useContext(LinksContext)

    const bundleName = useMemo(() => {
        return data?.bundles?.find((b) => b.id === selectedBundleIds[0])?.name
    }, [])

    return (
        <ThankYou
            subtitle={`Order for ${bundleName} has been placed successfully!`}
            footerWidth={mainContentWidth}
            partnerLogoUrl={data?.useLogo && data?.logo}
        />
    )
}

export default LinksThankYou
