import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'

import Button from 'componentsOld/Button'
import CurrencyPicker from 'componentsOld/CurrencyPicker/CurrencyPicker'
import { FormInput } from 'componentsOld/FieldComponents/FieldComponents'
import FormField from 'componentsOld/FormField'
import Loading from 'componentsOld/Loading'
import Select from 'componentsOld/Select'
import { AccountType, IAccount } from 'models/account'
import { CreateAccountRequest } from 'models/openDapi'

import styles from './AccountCreate.module.css'

export enum FormKeys {
    NAME = 'name',
    TYPE = 'type',
    CURRENCY = 'currency',
    BENEFICIARY = 'beneficiary',
}

interface FormModel extends Omit<Record<FormKeys, string>, FormKeys.BENEFICIARY> {
    [FormKeys.BENEFICIARY]?: string
}

const required = (value: any) => (value ? undefined : 'This is a required field')

const AccountCreate = ({
    organisationId,
    initialValues,
    onSubmit,
    onSuccess,
}: {
    organisationId: string
    initialValues: FormModel | undefined
    onSubmit: (data: CreateAccountRequest) => Promise<IAccount>
    onSuccess: (res: IAccount) => void
}) => {
    const onSubmitForm = async (values: CreateAccountRequest) => {
        try {
            const res = await onSubmit({
                name: values.name,
                type: values.type,
                currency: values.currency,
                organisationId: organisationId,
                beneficiary: values.beneficiary,
            })
            onSuccess(res)
        } catch (response) {
            const msg = `Something went wrong. Please contact support if this happens again.`
            toast(msg, { type: 'error' })
            return {
                [FORM_ERROR]: msg,
            }
        }
    }

    const accountTypes = Object.entries(AccountType).map(([key, value]) => ({
        label: key,
        value,
    }))
    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormField
                            label={'Name'}
                            component={
                                <Field
                                    name={FormKeys.NAME}
                                    component={FormInput}
                                    validate={required}
                                />
                            }
                            description={`The name of your account.`}
                        />
                        <FormField
                            label={'Type'}
                            component={
                                <Field
                                    name={FormKeys.TYPE}
                                    validate={required}
                                    component={({ input: { onChange, value }, meta }: any) => (
                                        <Select
                                            error={meta.touched && meta.error}
                                            value={accountTypes.find((t) => t.value === value)}
                                            options={accountTypes}
                                            onChange={(e: any) => onChange(e?.value)}
                                        />
                                    )}
                                />
                            }
                            description={`The type of your account.`}
                        />
                        <FormField
                            label={'Currency'}
                            component={
                                <Field
                                    name={FormKeys.CURRENCY}
                                    validate={required}
                                    component={({ input: { onChange, value }, meta }: any) => (
                                        <CurrencyPicker
                                            error={meta.touched && meta.error}
                                            value={value}
                                            onChange={(e) => onChange(e?.value)}
                                        />
                                    )}
                                />
                            }
                            description={`Account currency`}
                        />
                        <FormField
                            label={'Beneficiary'}
                            component={<Field name={FormKeys.BENEFICIARY} component={FormInput} />}
                            description={`The official beneficiary of your account's orders.`}
                        />
                        <div className={styles.Submit}>
                            <Button data-testid={`SubmitBtn`} type="submit" disabled={submitting}>
                                {submitting ? <Loading /> : 'Create Account'}
                            </Button>
                        </div>
                    </form>
                )
            }}
        />
    )
}

export default AccountCreate
