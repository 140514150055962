import { Bundle, OffsetLink, OffsetLinkRequest } from '@lune-climate/lune'
import { parseAddressList, ParsedMailbox } from 'email-addresses'
import { FORM_ERROR } from 'final-form'
import { isEmpty, isNil } from 'ramda'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'

import Button from 'componentsOld/Button'
import FormField from 'componentsOld/FormField'
import Input from 'componentsOld/Input'
import Loading from 'componentsOld/Loading'
import Select from 'componentsOld/Select'
import useAccounts from 'hooks/useAccounts'
import useUserState from 'hooks/useUserState'
import { AccountType } from 'models/account'
import { getCurrencySymbol } from 'models/currency'
import { OrderType } from 'models/order'
import FormIncludeLogoCheckbox from 'viewsOld/OffsetLinksForm/FormIncludeLogoCheckbox/FormIncludeLogoCheckbox'
import styles from 'viewsOld/OffsetLinksForm/OffsetLinksForm.module.css'
import {
    Condition,
    FormDescriptionTextArea,
    FormEmailTextArea,
    FormInput,
} from 'viewsOld/OffsetLinksForm/OffsetLinksFormComponents'

export const BUNDLE_MAX_SIZE = 24

const required = (value: any) => (value ? undefined : 'This is a required field')

export enum FormKeys {
    NAME = 'name',
    CTA_TITLE = 'cta_title',
    DESCRIPTION = 'description',
    BUNDLES = 'bundles',
    VALUE = 'value',
    MASS = 'mass',
    EMAILS = 'emails',
    TYPE = 'type',
    USE_LOGO = `use_logo`,
    REQUIRE_PAYMENT = 'require_payment',
}

interface FormModel
    extends Omit<
        Record<FormKeys, string>,
        FormKeys.USE_LOGO | FormKeys.BUNDLES | FormKeys.REQUIRE_PAYMENT | FormKeys.TYPE
    > {
    [FormKeys.USE_LOGO]: boolean
    [FormKeys.BUNDLES]: {
        label: string
        value: string
    }[]
    [FormKeys.REQUIRE_PAYMENT]: boolean
    [FormKeys.TYPE]: { label: string; value: OrderType }
}

const OffsetLinksForm = ({
    onSubmit,
    initialValues,
    bundles,
    onSuccess,
    submitButtonLabel,
    disableRequirePaymentsField,
}: {
    onSubmit: (data: OffsetLinkRequest) => Promise<OffsetLink | OffsetLinkRequest>
    initialValues: FormModel
    bundles: Bundle[]
    onSuccess: (res?: OffsetLink | OffsetLinkRequest) => void
    submitButtonLabel: string
    disableRequirePaymentsField?: boolean
}) => {
    const { userState } = useUserState()
    const { activeAccount } = useAccounts()
    const partnerLogoUrl = userState?.account.logo

    const onSubmitForm = async (values: FormModel) => {
        const requirePayment = values[FormKeys.REQUIRE_PAYMENT]
        const emails = values[FormKeys.EMAILS]

        if (requirePayment !== true && (isNil(emails) || isEmpty(emails))) {
            return {
                [FormKeys.EMAILS]:
                    activeAccount?.type === AccountType.LIVE
                        ? `Emails are mandatory when 'Require payment' is not set.`
                        : `Emails are required.`,
            }
        }

        if (values[FormKeys.BUNDLES]?.length > BUNDLE_MAX_SIZE) {
            return {
                [FormKeys.BUNDLES]: `Maximum size for bundles exceeded. 
            Please make sure a maximum of ${BUNDLE_MAX_SIZE} bundles are selected.`,
            }
        }

        const parsedEmails = parseAddressList(values[FormKeys.EMAILS])?.map(
            (a) => (a as ParsedMailbox).address,
        )

        if (requirePayment !== true && !parsedEmails?.length) {
            return {
                [FormKeys.EMAILS]: `Unable to parse the provided email list. 
            Please make sure the list consists only of comma separated, valid email addresses.`,
            }
        }

        try {
            const res = await onSubmit({
                bundles: values[FormKeys.BUNDLES].map((b) => b.value),
                value: values[FormKeys.VALUE].toString(),
                emails: parsedEmails,
                name: values.name,
                title: values.cta_title,
                description: values[FormKeys.DESCRIPTION],
                useLogo: values[FormKeys.USE_LOGO],
                requirePayment: requirePayment ?? false,
            })
            onSuccess(res)
        } catch (response) {
            const msg = `Something went wrong. Please contact support if this happens again.`
            toast(msg, { type: 'error' })
            return {
                [FORM_ERROR]: msg,
            }
        }
    }

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormField
                            label={'Name'}
                            component={
                                <Field
                                    name={FormKeys.NAME}
                                    component={FormInput}
                                    validate={required}
                                />
                            }
                            description={`The name of your new offset link. It won't be publicly accessible.`}
                        />

                        <FormField
                            label={'CTA Title (optional)'}
                            component={<Field name={FormKeys.CTA_TITLE} component={FormInput} />}
                            description={`The CTA title that will appear on the first page of the offset link flow.`}
                        />

                        <FormField
                            label={'Description (optional)'}
                            component={
                                <Field
                                    name={FormKeys.DESCRIPTION}
                                    component={FormDescriptionTextArea}
                                />
                            }
                            description={`The description that will appear on the first page of the offset link flow. This can be omitted.`}
                        />

                        <FormField
                            label={'Order type'}
                            component={
                                <Field
                                    name={FormKeys.TYPE}
                                    validate={required}
                                    component={({ input: { onChange, value } }: any) => (
                                        <Select
                                            isDisabled
                                            options={[
                                                { value: OrderType.QUANTITY, label: 'By mass' },
                                                { value: OrderType.VALUE, label: 'By price' },
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            }
                            description={`How to purchase the offset amount: by price or volume of CO2. 
                                Currently we only support offset by price for offset links.`}
                        />

                        <Condition when={FormKeys.TYPE} is={OrderType.QUANTITY}>
                            <FormField
                                label={'Quantity (tCO2)'}
                                component={
                                    <Field
                                        name={FormKeys.MASS}
                                        validate={required}
                                        component={FormInput}
                                    />
                                }
                            />
                        </Condition>

                        <Condition when={FormKeys.TYPE} is={OrderType.VALUE}>
                            <FormField
                                label={`Price (${getCurrencySymbol(
                                    userState!?.account?.currency,
                                )})`}
                                component={
                                    <Field
                                        name={FormKeys.VALUE}
                                        component={FormInput}
                                        validate={(value) => {
                                            if (Number.isNaN(value) || parseFloat(value) <= 0) {
                                                return `This needs to be a great-than-zero number`
                                            }

                                            return required(value)
                                        }}
                                    />
                                }
                                description={
                                    'The monetary amount each user will spend towards their offset grant.'
                                }
                            />
                        </Condition>

                        <FormField
                            label={'Bundles'}
                            description={`The bundles you would like to include in the Offset Link. A maximum of ${BUNDLE_MAX_SIZE} are allowed.`}
                            component={
                                <Field
                                    name={FormKeys.BUNDLES}
                                    validate={(value) => {
                                        return required(value?.length ? value : undefined)
                                    }}
                                    component={({ input: { onChange, value }, meta }: any) => (
                                        <Select
                                            // @ts-ignore
                                            isMulti={true}
                                            options={
                                                value.length === BUNDLE_MAX_SIZE
                                                    ? []
                                                    : bundles?.map((b) => ({
                                                          value: b.id,
                                                          label: b.name,
                                                      }))
                                            }
                                            noOptionsMessage={() => {
                                                return value.length === BUNDLE_MAX_SIZE
                                                    ? `You've already added the maximum allowed number of bundles`
                                                    : 'No options available'
                                            }}
                                            value={value}
                                            onChange={onChange}
                                            error={meta.touched && (meta.error || meta.submitError)}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormField
                            className={styles.SmallDownMargin}
                            component={
                                <Field
                                    name={FormKeys.USE_LOGO}
                                    component={({ input: { onChange, value } }: any) => (
                                        <FormIncludeLogoCheckbox
                                            partnerLogoUrl={partnerLogoUrl}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            }
                            label={`Show account logo`}
                            description={
                                <div>
                                    Select this option to include your account-level logo in the
                                    lower-left corner of the Offset Link view.
                                    <br />
                                    <br />
                                    <strong>Note:</strong> The logo is a shared, account-level
                                    resource. Changing it will affect all Offset Link instances
                                    configured to use it.
                                </div>
                            }
                        />

                        {activeAccount?.type === AccountType.LIVE && (
                            <FormField
                                className={styles.SmallDownMargin}
                                component={
                                    <Field
                                        name={FormKeys.REQUIRE_PAYMENT}
                                        component={({ input: { onChange, value } }: any) => (
                                            <Input
                                                disabled={!!disableRequirePaymentsField}
                                                type="checkbox"
                                                className={styles.RequirePaymentCheckbox}
                                                onChange={(e) => {
                                                    onChange(e.target.checked)
                                                }}
                                                data-testid={`FormInput-requirePayment`}
                                                checked={value}
                                            />
                                        )}
                                    />
                                }
                                label={`Require payment`}
                                description={
                                    <div>
                                        Select this option to request payment.
                                        <br />
                                        This option cannot be updated after the initial creation of
                                        the Offset Link.
                                    </div>
                                }
                            />
                        )}

                        <FormField
                            component={
                                <Field
                                    name={FormKeys.EMAILS}
                                    component={FormEmailTextArea}
                                    format={(value) => {
                                        return value?.trim()?.replace(/,\s*$/, '')?.trim()
                                    }}
                                    formatOnBlur
                                />
                            }
                            label={`Emails`}
                            description={`A comma separated list of email addresses used to restrict the offset link to a particular set of users.`}
                        />

                        <div className={styles.Submit}>
                            <Button data-testid={`SubmitBtn`} type="submit" disabled={submitting}>
                                {submitting ? <Loading /> : submitButtonLabel}
                            </Button>
                        </div>
                    </form>
                )
            }}
        />
    )
}

export default OffsetLinksForm
