import useActiveAccount from 'hooks/useActiveAccount'
import { AccountType } from 'models/account'

/**
 * Using this simple hook allows us to easily centralize instances of
 * "branching" logic for LIVE/TEST mode
 */
const useIsTestMode = () => {
    const { activeAccount, isLoadingActiveAccount } = useActiveAccount()
    return isLoadingActiveAccount ? undefined : activeAccount?.type === AccountType.TEST
}

export default useIsTestMode
