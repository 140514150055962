import { AccountType } from '@lune-climate/lune'
import { Account } from '@lune-climate/lune/esm/models/Account'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { luneClient } from 'endpoints/api'
import useUserState from 'hooks/useUserState'
import { queryKeys } from 'queryKeys'

const getAccounts = () => luneClient.listAccounts(`100`).then((r) => r.unwrap().data)

const useAccounts = () => {
    const { isLoading: loading, data: accounts = [], refetch: refetchAccounts } = useQuery<
        Account[]
    >(queryKeys.GET_ACCOUNTS, getAccounts, {
        enabled: true,
    })
    const { userState } = useUserState()

    const activeAccount = useMemo(() => {
        return accounts.find((a) => a.id === userState?.account.id)
    }, [accounts, userState])

    const liveAccounts = useMemo(() => {
        return accounts.filter((a) => a.type === AccountType.LIVE)
    }, [accounts, userState])

    const testAccounts = useMemo(() => {
        return accounts.filter((a) => a.type === AccountType.TEST)
    }, [accounts, userState])

    return {
        liveAccounts,
        testAccounts,
        refetchAccounts,
        loading,
        accounts,
        activeAccount,
    }
}

export default useAccounts
