import { Button, Text } from 'lune-ui-lib'
import { useSnackbar } from 'notistack'
import { isEmpty, isNil } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { verifyEmail } from 'endpoints/dapi'
import { SnackbarMessages } from 'SnackbarMessages'
import FormBox from 'views/Account/Shared/FormBox'
import Layout from 'views/Account/Shared/Layout'

interface LocationState {
    from: {
        pathname: string
    }
}

function extractToken(path: string): string {
    const items = path.split('/')
    return items[2]
}

const VerifyEmailConfirmation = () => {
    const location = useLocation<LocationState>()
    const token = extractToken(location.pathname)
    const [success, setSuccess] = useState<boolean | undefined>(undefined)
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const submitToken = async (token: string) => {
        verifyEmail(token).then(
            () => setSuccess(true),
            () => {
                setSuccess(false)
                snackbar(SnackbarMessages.EMAIL_VERIFICATION_FAIL)
            },
        )
    }

    useEffect(() => {
        if (!isNil(token) && !isEmpty(token)) {
            submitToken(token)
        }
    }, [])

    return (
        <Layout>
            {success === true && (
                <FormBox title={'Your email is verified'}>
                    <Text variant={'body1'}>You may now place orders.</Text>
                    <Link to="/login">
                        <Button sx={{ width: '100%', mb: 1, mt: 4 }}>Login</Button>
                    </Link>
                </FormBox>
            )}
        </Layout>
    )
}

export default VerifyEmailConfirmation
