import { Bundle, BundleMix } from '@lune-climate/lune'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { ButtonGroup } from 'lune-ui-lib'
import React, { FC, useMemo } from 'react'

import CustomBundleSelection from 'views/Settings/BundlePicker/CustomBundleSelection'
import PredefinedBundleAllocation from 'views/Settings/BundlePicker/PredefinedBundleAllocation'

const PickerView: FC<{ current: string; id: string }> = ({ current, id, children }) => (
    <div hidden={id !== current}>{id === current && <Box>{children}</Box>}</div>
)

export enum bundlePickerView {
    PERCENTAGES = 'percentages',
    SWITCHES = 'switches',
}

export const CUSTOM_MIX_ID = 'custom'

export const calculateAveragePrice = (
    bundles: Bundle[],
    bundleSelection: Record<string, number>,
) => {
    if (!bundleSelection) {
        return new Big(0)
    }
    return Object.entries(bundleSelection).reduce((acc: Big, [id, percentage]) => {
        const ratio = new Big(percentage).div(new Big(100))
        const price = bundles.find((b) => b.id === id)?.grossUnitPrice || 0
        return acc.add(ratio.mul(new Big(price)))
    }, new Big(0))
}

const BundlePicker: FC<{
    view: bundlePickerView
    readOnly?: boolean
    allBundles: Bundle[]
    predefinedBundleMixes: BundleMix[]
    selectedBundleMixId: string
    onChangeBundleMix: (selectedMixId: string) => void
    customBundleSelection: Record<string, number>
    onChangeCustomBundleSelection: (allocation: Record<string, number>) => void
    allocationVolume?: Record<string, number>
}> = ({
    view,
    readOnly,
    allBundles,
    customBundleSelection,
    predefinedBundleMixes,
    selectedBundleMixId = CUSTOM_MIX_ID,
    onChangeBundleMix,
    onChangeCustomBundleSelection,
    allocationVolume,
}) => {
    const tabItems = useMemo(
        () => [
            {
                label: `Custom`,
                value: CUSTOM_MIX_ID,
            },
            ...predefinedBundleMixes.map(({ label, id }) => ({ label, value: id })),
        ],
        [predefinedBundleMixes],
    )

    return (
        <>
            {view === bundlePickerView.PERCENTAGES && (
                <ButtonGroup
                    items={tabItems}
                    value={selectedBundleMixId}
                    onChange={onChangeBundleMix}
                    disabled={readOnly}
                />
            )}
            <Box
                sx={{
                    mt: 3,
                }}
            >
                <PickerView current={selectedBundleMixId} id={CUSTOM_MIX_ID}>
                    <CustomBundleSelection
                        allocationVolume={allocationVolume}
                        view={view}
                        readOnly={readOnly}
                        bundles={allBundles}
                        bundleSelection={customBundleSelection}
                        onChange={onChangeCustomBundleSelection}
                    />
                </PickerView>

                {predefinedBundleMixes.map(({ bundleSelection, id }) => (
                    <PickerView key={id} id={id} current={selectedBundleMixId}>
                        <PredefinedBundleAllocation
                            allocationVolume={allocationVolume}
                            bundles={allBundles}
                            bundleSelection={bundleSelection.reduce((acc, curr) => {
                                acc[curr.bundleId] = curr.percentage
                                return acc
                            }, {} as Record<string, number>)}
                        />
                    </PickerView>
                ))}
            </Box>
        </>
    )
}

export default BundlePicker
