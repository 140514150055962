import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { LuneTheme, LuneThemeProvider } from 'lune-ui-lib'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import DashboardApp from 'apps/DashboardApp'
import LinksApp from 'apps/LinksApp'
import { configCatClient } from 'config/ConfigCat'
import ErrorPage from 'viewsOld/ErrorPage'

import SnackbarProvider from './components/SnackbarProvider'
import reportWebVitals from './reportWebVitals'

import './index.css'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            staleTime: 1000 * 60 * 15, // 15 mins
            cacheTime: 1000 * 60 * 15, // 15 mins
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
})

const app = (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LuneThemeProvider theme={LuneTheme}>
                <SnackbarProvider>
                    {process.env.REACT_APP_USE_LINKS_APP ? <LinksApp /> : <DashboardApp />}
                </SnackbarProvider>
            </LuneThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
)

const root = document.getElementById('root')

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
} else {
    mixpanel.init('<dummy>', { test: true, debug: true })
    mixpanel.disable()
}

if (process.env.REACT_APP_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
    })
    const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React)
    ReactDOM.render(
        // @ts-ignore
        <ErrorBoundary FallbackComponent={ErrorPage}>{app}</ErrorBoundary>,
        root,
    )
} else {
    ReactDOM.render(app, root)
}

if (configCatClient) {
    configCatClient.forceRefresh(() =>
        console.log(`Latest feature flag and configuration values successfully downloaded.`),
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
