import styles from './SmallBundleListItem.module.css'

const SmallBundleListItem = ({ title, imgUrl }: { title: string; imgUrl: string }) => {
    return (
        <>
            <div className={styles.Container}>
                <div className={styles.Img} style={{ backgroundImage: `url(${imgUrl})` }} />
                <div className={styles.Title}>{title}</div>
            </div>
        </>
    )
}

export default SmallBundleListItem
