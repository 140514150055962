import React from 'react'

import styles from './ProjectTypeTag.module.css'

const ProjectTypeTag = ({
    projectType,
    className,
}: {
    projectType: string
    className?: string
}) => {
    return <div className={`${styles.ProjectType} ${className || ''}`}>{projectType}</div>
}

export default ProjectTypeTag
