import { OffsetLink } from '@lune-climate/lune'
import { Elements } from '@stripe/react-stripe-js'
import type { Stripe } from '@stripe/stripe-js'
// Very important – only import loadStripe from the /pure module so that
// there are no side effects until loadStripe() is actually called.
import { loadStripe } from '@stripe/stripe-js/pure'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import useMetaTags from 'react-metatags-hook'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import LinksBundleDetails from 'apps/LinksApp/LinksBundleDetails'
import LinksBundleSelection from 'apps/LinksApp/LinksBundleSelection'
import LinksConfirm from 'apps/LinksApp/LinksConfirm'
import LinksNotFoundError from 'apps/LinksApp/LinksNotFoundError'
import LinksProjectDetails from 'apps/LinksApp/LinksProjectDetails'
import LinksThankYou from 'apps/LinksApp/LinksThankYou'
import LoadingWrapper from 'componentsOld/LoadingWrapper'
import { getOffsetLink } from 'endpoints/dapi'

// Access this only through getStripePromise().
let stripePromise: Promise<Stripe | null> | undefined

function getStripePromise(): Promise<Stripe | null> {
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    //
    // But also: avoid calling loadStripe() unless it's actually necessary.
    // This should prevent Stripe from doing things unless LinksApp is actually
    // used.
    if (!stripePromise) {
        if (process.env.REACT_APP_STRIPE_PK) {
            // if statement is required because the dashboard does not require stripe

            // This prevents Stripe from "sending telemetry" in the "best interests of the user"
            // (AKA spying on people).
            //
            // More on this: https://mtlynch.io/stripe-recording-its-customers/
            loadStripe.setLoadParameters({ advancedFraudSignals: false })

            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!)
        } else {
            stripePromise = new Promise((resolve) => resolve(null))
        }
    }
    return stripePromise
}

export const LOCAL_STORAGE_SELECTED_BUNDLE_KEY = `selectedBundleId`

export const extractOffsetLinkIdFromPath = (path: string) => {
    if (path.includes(`thanks`)) {
        return
    }
    const pathNoLeadSlash = path.substring(1)

    return pathNoLeadSlash.includes(`/`)
        ? pathNoLeadSlash.substring(0, pathNoLeadSlash.indexOf(`/`))
        : pathNoLeadSlash
}

export const LinksContext = React.createContext<{
    data?: OffsetLink
    selectedBundleIds: string[]
    setData: (_: OffsetLink) => void
    mainContentWidth?: number
    setSelectedBundleIds: (_: string[]) => void
    setMainContentWidth?: any
}>({
    selectedBundleIds: [],
    mainContentWidth: undefined,
    setData: () => {},
    setSelectedBundleIds: () => {},
    setMainContentWidth: () => {},
})

const LinksApp = () => {
    useMetaTags(
        {
            title: `Lune Links`,
            charset: 'utf8',
            lang: 'en',
            metas: [
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1, maximum-scale=1',
                },
            ],
        },
        [],
    )

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<OffsetLink>()
    const [selectedBundleIds, setSelectedBundleIds] = useState<string[]>([])
    const [mainContentWidth, setMainContentWidth] = useState<number>()

    useEffect(() => {
        const offsetLinkId = extractOffsetLinkIdFromPath(window.location.pathname)

        if (offsetLinkId && !data) {
            getOffsetLink(offsetLinkId)
                .then((res) => {
                    setData(res)
                    mixpanel.track('OffsetLinkView', {
                        id: res.id,
                        url: res.url,
                    })
                })
                .catch(() => {
                    const badPath = window.location.pathname
                    window.location.replace(`${window.location.origin}?redirectedFrom=${badPath}`)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [data])

    return (
        <Elements stripe={getStripePromise()}>
            <LinksContext.Provider
                value={{
                    data,
                    setData,
                    selectedBundleIds,
                    setSelectedBundleIds: setSelectedBundleIds,
                    setMainContentWidth,
                    mainContentWidth,
                }}
            >
                <LoadingWrapper loading={loading}>
                    <Router>
                        <Switch>
                            <Route exact path="/thanks" component={LinksThankYou} />
                            <Route exact path="/:id/confirm/" component={LinksConfirm} />
                            <Route
                                exact
                                path="/:id/explore/project/:projectId"
                                component={LinksProjectDetails}
                            />
                            <Route
                                exact
                                path="/:id/explore/:view?"
                                component={LinksBundleDetails}
                            />
                            <Route exact path="/:id" component={LinksBundleSelection} />
                            <Route exact path="/*" component={LinksNotFoundError} />
                        </Switch>
                    </Router>
                </LoadingWrapper>
                <ToastContainer
                    toastStyle={{ marginTop: `8px` }}
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    rtl={false}
                />
            </LinksContext.Provider>
        </Elements>
    )
}

export default LinksApp
