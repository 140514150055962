import styles from './FieldValidationError.module.css'

const FieldValidationError = ({ msg }: { msg: string }) => {
    return (
        <div data-testid={`validation-err`} className={styles.Container}>
            {msg}
        </div>
    )
}

export default FieldValidationError
