import { ReactComponent as CheckCircle } from 'images/check-circle.svg'
import { ReactComponent as SVGMap } from 'images/World_map_with_points.svg'
import Footer from 'viewsOld/ProjectExplorer/Footer'

import styles from './ThankYou.module.css'

const hotspotCoordinates = [
    {
        x: 8,
        y: 30,
        size: 22.5,
    },
    {
        x: 20,
        y: 55,
        size: 22.5,
    },
    {
        x: 40,
        y: 45,
        size: 32.5,
    },
    {
        x: 83,
        y: 50,
        size: 30,
    },
    {
        x: 77,
        y: 55,
        size: 17.5,
    },
    {
        x: 80,
        y: 40,
        size: 15,
    },
]

const SmallBlob = ({ size, x, y }: { size: number; x: number; y: number }) => {
    return (
        <div
            id={`blob`}
            style={{
                height: size,
                width: size,
                top: `${y}%`,
                left: `${x}%`,
                position: 'absolute',
                zIndex: 1,
                borderRadius: `100px`,
            }}
            className={styles.Blob}
        />
    )
}

const ThankYou = ({
    subtitle,
    footerWidth,
    partnerLogoUrl,
}: {
    subtitle: string
    footerWidth?: number
    partnerLogoUrl?: string | false
}) => {
    return (
        <div className={styles.Container}>
            <CheckCircle className={styles.Check} />
            <div className={styles.Title}>Success!</div>
            <div className={styles.Subtitle}>{subtitle}</div>
            <div className={styles.MapContainer}>
                <div className={styles.Hotspots}>
                    {hotspotCoordinates.map(({ size, x, y }, index) => (
                        <span key={`blob-${index}`}>
                            <SmallBlob size={size} x={x} y={y} />
                        </span>
                    ))}
                </div>
                <div className={styles.GradientBackground} />
                <SVGMap className={styles.BigMap} />
            </div>
            <Footer
                className={styles.HideFooterOnMobile}
                width={footerWidth}
                partnerLogoUrl={partnerLogoUrl}
            />
        </div>
    )
}

export default ThankYou
