import React, { useEffect, useLayoutEffect } from 'react'

const useContainerHeight = (containerId: string): number | undefined => {
    const [parentHeight, setParentHeight] = React.useState(0)

    const setHeight = () => {
        const box = document.getElementById(containerId)
        setParentHeight(box?.offsetHeight!)
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', setHeight)
        setHeight()
        return () => window.removeEventListener('resize', setHeight)
    }, [])

    useEffect(() => {}, [])

    return parentHeight
}

export default useContainerHeight
