import { OffsetLink } from '@lune-climate/lune'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import BackButton from 'componentsOld/BackButton'
import Button from 'componentsOld/Button'
import CardSection from 'componentsOld/CardSection'
import FormField from 'componentsOld/FormField'
import Input from 'componentsOld/Input'
import LoadingWrapper from 'componentsOld/LoadingWrapper'
import { getCurrencySymbol } from 'models/currency'
import BigTitle from 'viewsOld/ProjectExplorer/BigTitle/BigTitle'
import SmallBundleListItem from 'viewsOld/ProjectExplorer/Confirm/SmallBundleListItem'
import Footer from 'viewsOld/ProjectExplorer/Footer'
import GradientBlobBackground from 'viewsOld/ProjectExplorer/GradientBlobBackground'

import styles from './Confirm.module.css'

const Confirm = ({
    data,
    selectedBundleIds,
    onSubmit,
    footerWidth,
    partnerLogoUrl,
}: {
    onSubmit: (data: { email: string; card?: StripeCardNumberElement }) => void
    data: OffsetLink
    selectedBundleIds: string[]
    footerWidth?: number
    partnerLogoUrl?: string | false
}) => {
    const [email, setEmail] = useState<string>()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [card, setCard] = useState<StripeCardNumberElement>()
    const history = useHistory()

    const isValid = useMemo(() => {
        if (data.requirePayment) {
            return email !== undefined && card
        }
        return email !== undefined
    }, [email, card, data])

    const onConfirm = useCallback(
        async (email: string) => {
            setSubmitting(true)
            await onSubmit({ email, card })
            setSubmitting(false)
        },
        [card, email],
    )

    return (
        <>
            <GradientBlobBackground>
                <div className={styles.FullContentWrapper}>
                    <div className={styles.OuterContainer}>
                        <div className={styles.InnerContainer}>
                            <div
                                className={styles.BackButtonWrapper}
                                style={{
                                    left: footerWidth
                                        ? `calc((100vw - ${footerWidth}px) / 2)`
                                        : `10%`,
                                }}
                            >
                                <BackButton onClick={() => history.goBack()} />
                            </div>
                            <BigTitle title={`Finalize`} />
                            <div className={styles.SubTitle}>
                                Enter your email below to confirm your bundle selection.
                            </div>
                            <div className={styles.Container}>
                                {data?.bundles
                                    ?.filter((b) => selectedBundleIds?.includes(b.id))
                                    .map((b) => (
                                        <span key={b.id}>
                                            <SmallBundleListItem
                                                title={b.name}
                                                imgUrl={b?.primaryImage!}
                                            />
                                        </span>
                                    ))}
                            </div>

                            {data.requirePayment && data.currency && data.value && (
                                <div className={styles.Header}>
                                    <span className={styles.Left}>Total Price:</span>
                                    <span className={styles.Right}>
                                        {getCurrencySymbol(data.currency)}
                                        {data.value}
                                    </span>
                                    <div className="clearfix"></div>
                                </div>
                            )}

                            {data.requirePayment && (
                                <div className={styles.CardSection}>
                                    <CardSection onChange={(card) => setCard(card)} />
                                </div>
                            )}

                            <FormField
                                label={'Your email'}
                                className={`${data.requirePayment ? '' : styles.MarginTop}`}
                                component={
                                    <Input
                                        data-testid={`emailInput`}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                }
                            />

                            <div className={styles.ButtonWrapper}>
                                <LoadingWrapper loading={submitting}>
                                    <Button
                                        data-testid={`linksSubmitBtn`}
                                        disabled={!isValid}
                                        className={styles.SubmitButton}
                                        onClick={() => onConfirm(email!)}
                                    >
                                        {data.requirePayment && 'Pay now and '}Confirm
                                    </Button>
                                </LoadingWrapper>
                            </div>
                        </div>
                    </div>
                    <Footer
                        className={styles.HideFooterOnMobile}
                        width={footerWidth}
                        partnerLogoUrl={partnerLogoUrl}
                    />
                </div>
            </GradientBlobBackground>
        </>
    )
}

export default Confirm
