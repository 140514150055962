import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import Whoops404Page from 'pages/Whoops404Page'

const LinksNotFoundError = () => {
    const search = useLocation().search

    useEffect(() => {
        const id = new URLSearchParams(search).get('id')
        if (id) {
            toast(
                `Looks like we couldn't find offset link "${id}". Please contact support if this happens again.`,
                { type: 'error', autoClose: false },
            )
        }
    }, [search])

    return <Whoops404Page />
}

export default LinksNotFoundError
