import Check from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FORM_ERROR } from 'final-form'
import { Button, LabeledFormSection, MainLayoutContainer, Select, Text } from 'lune-ui-lib'
import { useSnackbar } from 'notistack'
import React, { useCallback, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import LoadingWrapper from 'componentsOld/LoadingWrapper'
import { createApiKey } from 'endpoints/dapi'
import useAccounts from 'hooks/useAccounts'
import { queryKeys } from 'queryKeys'
import SecretTokenModal from 'views/Developers/SecretTokenModal'

const required = (value: any) => (value ? undefined : 'This is a required field')

export enum AccountFormKeys {
    NAME = 'name',
    DEFAULT_ACCOUNT = 'defaultAccount',
}

interface FormModel {
    [AccountFormKeys.NAME]: string
    [AccountFormKeys.DEFAULT_ACCOUNT]: string
}

const CreateApiKey = () => {
    const reactQueryClient = useQueryClient()
    const history = useHistory()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { activeAccount, accounts, loading } = useAccounts()
    const [secretToken, setSecretToken] = useState<string>()

    const accountsOptions = useMemo(() => {
        if (!accounts?.length || !activeAccount) {
            return []
        }

        const filteredAccounts = accounts.filter(
            (acc) =>
                acc.organisationId === activeAccount?.organisationId &&
                acc.type === activeAccount.type,
        )

        return filteredAccounts.map((acc) => ({
            value: acc.id,
            label: acc.name,
        }))
    }, [accounts, activeAccount])

    const FormSelect = useCallback(
        ({ input: { onChange, value }, meta }: any) => (
            <Select
                sx={{
                    width: '100%',
                }}
                error={meta.touched && meta.error}
                value={value}
                onChange={onChange}
                items={accountsOptions}
            />
        ),
        [accountsOptions],
    )

    const onSubmit = async (values: FormModel) => {
        try {
            const secret = await createApiKey({
                name: values[AccountFormKeys.NAME],
                accountId: values[AccountFormKeys.DEFAULT_ACCOUNT],
            })
            setSecretToken(secret)
            await reactQueryClient.invalidateQueries(queryKeys.GET_API_KEYS)
        } catch (response) {
            const msg = `Something went wrong. Please contact support if this happens again.`
            snackbar(msg)
            return {
                [FORM_ERROR]: msg,
            }
        }
    }

    return (
        <>
            <Form
                initialValues={{
                    [AccountFormKeys.NAME]: '',
                    [AccountFormKeys.DEFAULT_ACCOUNT]: activeAccount?.id,
                }}
                onSubmit={onSubmit}
                render={({ handleSubmit, valid, submitting, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <MainLayoutContainer
                            headerComponent={
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mb: 6,
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            iconButton
                                            leftIcon={<CloseIcon />}
                                            sx={{
                                                ml: -1.5,
                                            }}
                                            onClick={() => history.push('/developers')}
                                        />
                                        <Button
                                            leftIcon={<Check />}
                                            type="submit"
                                            disabled={submitting || !dirty || !valid}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                    <Text variant="h4">New API Key</Text>
                                </Box>
                            }
                        >
                            <Stack
                                direction="column"
                                sx={{
                                    mb: 9,
                                    width: '100%',
                                }}
                                spacing={{
                                    xs: 9,
                                }}
                            >
                                <LabeledFormSection title={'Name'}>
                                    <Field
                                        name={AccountFormKeys.NAME}
                                        placeholder={'Name'}
                                        component={FormInput}
                                        validate={required}
                                    />
                                </LabeledFormSection>

                                <LoadingWrapper loading={loading}>
                                    <LabeledFormSection title={'Default account'}>
                                        <Field
                                            name={AccountFormKeys.DEFAULT_ACCOUNT}
                                            component={FormSelect}
                                            validate={required}
                                        />
                                    </LabeledFormSection>
                                </LoadingWrapper>
                            </Stack>
                        </MainLayoutContainer>
                    </form>
                )}
            />
            {secretToken && (
                <SecretTokenModal
                    secretToken={secretToken}
                    onClose={() => history.push(`/developers`)}
                />
            )}
        </>
    )
}

export default CreateApiKey
