import { ProjectSummary } from '@lune-climate/lune'
import React from 'react'
import ReactMapGL from 'react-map-gl'

import ProjectMarker from 'viewsOld/ProjectExplorer/BundleDetails/ProjectMap/ProjectMarker'
import { useMap } from 'viewsOld/ProjectExplorer/BundleDetails/ProjectMap/useMap'

import styles from '../ProjectDetails.module.css'

const IndividualProjectMap = ({ project }: { project: ProjectSummary }) => {
    const { bounds } = useMap({
        width: 500,
        height: 264,
        markers: [
            {
                latitude: project.latitude,
                longitude: project.longitude,
            },
        ],
    })

    const [viewport, setViewport] = React.useState({
        ...bounds,
    })

    return (
        <div className={styles.Map}>
            <ReactMapGL
                className={styles.MapInner}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                mapStyle={`mapbox://styles/mapbox/outdoors-v11`}
                maxZoom={20}
                {...viewport}
                onViewportChange={setViewport}
            >
                <ProjectMarker project={project} />
            </ReactMapGL>
        </div>
    )
}

export default IndividualProjectMap
