import FieldValidationError from 'componentsOld/FieldValidationError'

import './Input.css'

interface IInputProps {
    id?: string
    defaultValue?: string
    disabled?: boolean
    error?: string
    placeholder?: string
    type?: 'text' | 'password' | 'checkbox' | 'number'
    name?: string
    onChange?: (f: any) => void
    onBlur?: (f: any) => void
    register?: any
    spellCheck?: string
    value?: string
    className?: string
    checked?: boolean
    autoComplete?: boolean
}

export default function Input({
    id,
    name,
    register,
    defaultValue,
    disabled,
    error,
    placeholder,
    type,
    onChange,
    onBlur,
    spellCheck,
    className,
    autoComplete,
    ...rest
}: IInputProps) {
    return (
        <>
            <input
                {...(id && { id })}
                {...(defaultValue && { defaultValue })}
                {...(placeholder && { placeholder })}
                {...(onChange && { onChange })}
                {...(onBlur && { onBlur })}
                {...(register && { ref: register })}
                {...(name && { name })}
                {...(spellCheck && { spellCheck })}
                className={`input ${error ? 'error' : ''} ${className || ''}`}
                type={type ?? 'text'}
                disabled={disabled ?? false}
                {...rest}
                autoComplete={autoComplete}
            />
            {error && <FieldValidationError msg={error} />}
        </>
    )
}
