export function getCurrencySymbol(code: string): string {
    switch (code) {
        case 'GBP':
            return '£'
        case 'EUR':
            return '€'
        case 'USD':
            return '$'
        case 'SEK':
            return 'kr'
        case 'CHF':
            return 'CHf'
        case 'PLN':
            return 'zł'
        case 'DKK':
            return 'Kr.'
        case 'NOK':
            return 'kr'
    }
    return ''
}

export function addTwoDecimalPoints(number: string) {
    return Number.parseFloat(number).toFixed(2)
}
