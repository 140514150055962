import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import './TabsWithPaths.css'

interface ITabProps {
    title: string
    path: string
    children: React.ReactNode
}

interface ITabsWithPathsProps {
    path: string
    children: React.ReactNode[]
    isSubTab?: boolean
}

const removeSlashes = (str: string): string => str.replace('/', '')

const TabsWithPaths = ({ path, children, isSubTab }: ITabsWithPathsProps) => {
    const basePath = path
    const { activeTab, activeSubTab } = useParams<{ activeTab?: string; activeSubTab?: string }>()
    const finalActiveTab = isSubTab ? activeSubTab : activeTab
    const history = useHistory()

    const push = (path: string): void => {
        history.push(`/${removeSlashes(basePath)}/${removeSlashes(path)}`)
    }
    const replace = (path: string): void => {
        history.replace(`/${removeSlashes(basePath)}/${removeSlashes(path)}`)
    }
    useEffect(() => {
        if (!finalActiveTab) {
            const firstChild = (children[0] as any)?.props as ITabProps
            replace(firstChild.path)
        }
    }, [])

    return (
        <div className="tab-content-wrapper">
            <div className="tabs">
                {children.map((c: any) => (
                    <div
                        key={c.props.title}
                        className={`tab${
                            removeSlashes(c.props.path) === finalActiveTab ? ' tab-active' : ''
                        }`}
                        onClick={(e) => {
                            if (!e.currentTarget.classList.contains(`tab-active`)) {
                                push(c.props.path)
                            }
                        }}
                    >
                        {c.props.title}
                    </div>
                ))}
            </div>
            {children.find((c: any) => removeSlashes(c.props.path) === finalActiveTab)}
        </div>
    )
}

TabsWithPaths.Tab = ({ children }: ITabProps) => {
    return <>{children}</>
}

export default TabsWithPaths
