import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import Loading from 'componentsOld/Loading'

import styles from './OrdersBarChart.module.css'

enum barChartColors {
    BLUE = `#3C5DD7`,
    BLUE_DARK = `#2c459e`,
    SKY_BLUE = `#0597F2`,
    SKY_BLUE_DARK = `#0276bd`,
}

export interface Dataset {
    data: { x: string; y: string }[]
    label: string
}

const MIN_BAR_HEIGHT = 5

const OrdersBarChart = ({
    datasets,
    title,
    loading,
}: {
    datasets?: Dataset[]
    title?: string
    loading?: boolean
}) => {
    const isDataEmpty = datasets?.every(({ data }) => data.every(({ y }) => y.toString() === `0`))
    const isReadyToDisplay = !isDataEmpty && !loading

    const dataReadyForBarChart = useMemo(() => {
        if (!datasets || !datasets.length) {
            return {
                labels: [],
                datasets: [],
            }
        }

        return {
            labels: Array.from(
                new Set(
                    datasets.reduce((acc: string[], curr) => {
                        return [...acc, ...curr.data.map(({ x }) => x)]
                    }, []),
                ),
            ),
            datasets: datasets.map(({ data, label }, index) => ({
                data,
                label,
                backgroundColor: index % 2 === 0 ? barChartColors.BLUE : barChartColors.SKY_BLUE,
                hoverBackgroundColor:
                    index % 2 === 0 ? barChartColors.BLUE_DARK : barChartColors.SKY_BLUE_DARK,
                minBarLength: isDataEmpty ? 0 : MIN_BAR_HEIGHT,
            })),
        }
    }, [datasets])

    const gridConfiguration = useMemo(
        () => ({
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: isReadyToDisplay,
                        autoSkip: true,
                        maxTicksLimit: 15,
                        maxRotation: 0,
                        color: `#727373`,
                        font: {
                            family: 'Raleway',
                            size: 12,
                        },
                    },
                },
                y: {
                    display: isReadyToDisplay,
                    stacked: true,
                    grid: {
                        display: isReadyToDisplay,
                        drawBorder: false,
                    },
                    min: 0,
                    ticks: {
                        display: isReadyToDisplay,
                        precision: 0,
                        color: `#727373`,
                        font: {
                            family: 'Raleway',
                            size: 12,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: datasets && datasets.length > 1 && isReadyToDisplay,
                    position: 'top' as any,
                    labels: {
                        font: {
                            family: 'Raleway',
                        },
                    },
                },
                title: {
                    display: !!title,
                    align: 'start' as any,
                    font: {
                        family: 'Raleway',
                        size: 18,
                        weight: 'normal',
                    },
                    color: '#3C5DD7',
                    text: title,
                },
                tooltip: {
                    enabled: isReadyToDisplay,
                    intersect: false,
                    mode: 'index' as any,
                    yAlign: 'bottom' as any,
                    position: 'nearest' as any,
                },
            },
        }),
        [title, datasets, isReadyToDisplay],
    )

    return (
        <div style={{ position: `relative` }}>
            {isDataEmpty && !loading && (
                <div className={styles.NoDataMsg}>
                    You don't have any orders for the selected time period.
                </div>
            )}
            {loading && (
                <div style={{ position: `absolute`, width: `100%`, height: `100%` }}>
                    <Loading />
                </div>
            )}
            <Bar data={dataReadyForBarChart} options={gridConfiguration} />
        </div>
    )
}

export default OrdersBarChart
