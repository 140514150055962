import { Input, LabeledFormSection, MainLayoutContainer, Text } from 'lune-ui-lib'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect } from 'react'

import { updateOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import InviteTeamMembers from 'views/Settings/OrganisationSettings/InviteTeamMembers'
import ManageLogo from 'views/Settings/OrganisationSettings/ManageLogo'
import ManageTeam from 'views/Settings/OrganisationSettings/ManageTeam'

export enum Role {
    ADMIN = 'admin',
    USER = 'user',
}

const OrganisationSettings: FC = () => {
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()
    const [localName, setLocalName] = React.useState(activeOrg?.name || ``)
    const [localBeneficiary, setLocalBeneficiary] = React.useState(activeOrg?.beneficiary || ``)
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const isAuthorised = useIsAuthorised()

    useEffect(() => {
        if (activeOrg) {
            setLocalName(activeOrg.name)
            setLocalBeneficiary(activeOrg.beneficiary)
        }
    }, [activeOrg])

    const updateOrg = useCallback(
        ({ name, beneficiary }) =>
            updateOrganisation(activeOrg?.id || ``, {
                beneficiary,
                name,
            })
                .then(() => {
                    refetchActiveOrg()
                    snackbar(SnackbarMessages.SETTINGS_SAVED)
                })
                .catch(() => {
                    snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
                }),
        [activeOrg],
    )

    return (
        <MainLayoutContainer headerComponent={<Text variant={'h4'}>Organisation</Text>}>
            <LabeledFormSection
                title={`Name & logo`}
                sx={{
                    mb: 5.5,
                }}
            >
                <Input
                    disabled={!isAuthorised}
                    wide
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}
                    onBlur={(e) => {
                        const trimmedName = e.target.value.trim()
                        if (trimmedName === ``) {
                            setLocalName(activeOrg?.name!)
                            snackbar(SnackbarMessages.ORGANISATION_NAME_UPDATE_FAIL)
                            return
                        }
                        if (trimmedName === activeOrg?.name) {
                            return
                        }
                        return updateOrg({
                            name: trimmedName,
                            beneficiary: activeOrg?.beneficiary,
                        })
                    }}
                />
            </LabeledFormSection>

            <ManageLogo />

            <LabeledFormSection
                title={`Beneficiary`}
                subTile={`The beneficiary will be shown on the Carbon Offset Certificate. It will also appear on the carbon registries (e.g. Verra).`}
                sx={{
                    mt: 10.5,
                    mb: 9,
                }}
            >
                <Input
                    disabled={!isAuthorised}
                    wide
                    value={localBeneficiary}
                    onChange={(e) => setLocalBeneficiary(e.target.value)}
                    onBlur={(e) => {
                        const ben = e.target.value.trim()
                        if (ben === ``) {
                            setLocalBeneficiary(activeOrg?.beneficiary!)
                            snackbar(SnackbarMessages.ORGANISATION_BENEFICIARY_UPDATE_FAIL)
                            return
                        }
                        if (ben === activeOrg?.beneficiary) {
                            return
                        }
                        return updateOrg({
                            name: activeOrg?.name,
                            beneficiary: ben,
                        })
                    }}
                />
            </LabeledFormSection>

            <ManageTeam />
            <InviteTeamMembers />
        </MainLayoutContainer>
    )
}

export default OrganisationSettings
