import React from 'react'

import style from './SwitchButtonGroup.module.css'

export enum SwitchViews {
    LIST = 'List',
    MAP = 'Map',
}

const SwitchButtonGroup = ({
    activeSelection,
    onSwitch,
}: {
    activeSelection: SwitchViews
    onSwitch: (view: SwitchViews) => void
}) => {
    return (
        <div className={style.SwitchGroup}>
            <div
                className={`${style.Button} ${
                    activeSelection === SwitchViews.LIST ? style.ActiveLeft : ''
                }`}
                onClick={() => onSwitch(SwitchViews.LIST)}
            >
                {SwitchViews.LIST}
            </div>
            <div
                className={`${style.Button} ${
                    activeSelection === SwitchViews.MAP ? style.ActiveRight : style.InactiveRight
                }`}
                onClick={() => onSwitch(SwitchViews.MAP)}
            >
                {SwitchViews.MAP}
            </div>
        </div>
    )
}

export default SwitchButtonGroup
