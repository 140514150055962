import { ProjectSummary } from '@lune-climate/lune'
import React from 'react'
import { Marker } from 'react-map-gl'

import styles from './ProjectMarker.module.css'

const ProjectMarker = ({ project }: { project: ProjectSummary }) => {
    const { longitude, latitude } = project

    return (
        <Marker longitude={longitude} latitude={latitude} className={styles.Marker}>
            <div
                className={styles.MarkerInner}
                style={{
                    backgroundImage: `url(${project?.thumbnailImage})`,
                }}
            />
            <div
                className={styles.MarkerTransparentBorder}
                style={{
                    backgroundImage: `url(${project?.thumbnailImage})`,
                }}
            />
        </Marker>
    )
}

export default ProjectMarker
