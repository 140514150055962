import React from 'react'
import Collapsible from 'react-collapsible'

import CircleButton from 'componentsOld/CircleButton'
import { truncateStringWithoutCutWords } from 'utils'

import styles from './BundleListItem.module.css'

const BundleListItem = ({
    active,
    onSelect,
    imageUrl,
    name,
    price,
    description,
    onExplore,
}: {
    active?: boolean
    onSelect?: () => void
    imageUrl: string
    name: string
    price?: string
    description?: string
    onExplore: () => void
}) => {
    const [isExtendedDescription, setIsExtendedDescription] = React.useState<boolean>(false)
    const truncatedDescription = `${truncateStringWithoutCutWords(description, 48)}... `

    const backgroundImageStyle = {
        backgroundImage: `${
            active
                ? `linear-gradient( rgba(108,129,207, 0.9), rgba(108,129,207, 0.45) )`
                : `linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 100%)`
        }, url(${imageUrl})`,
    }

    return (
        <div style={backgroundImageStyle} className={styles.Container}>
            {description && isExtendedDescription && (
                <div className={styles.BlurContainer}>
                    <div style={backgroundImageStyle} className={styles.Blur} />
                </div>
            )}
            <div className={styles.Content} onClick={() => onExplore?.()}>
                {price && <div>{price}</div>}
                <div>
                    <div className={styles.Title}>{name}</div>
                    {description && (
                        <Collapsible
                            open={isExtendedDescription}
                            easing={`cubic-bezier(0,1.15,.49,.95)`}
                            trigger={
                                <div
                                    className={styles.Description}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsExtendedDescription((prev) => !prev)
                                    }}
                                    style={{ cursor: `pointer` }}
                                >
                                    {truncatedDescription}
                                    <span style={{ textDecoration: `underline` }}>more</span>
                                </div>
                            }
                            triggerWhenOpen={<></>}
                        >
                            <div className={styles.Description}>{description}</div>
                        </Collapsible>
                    )}
                </div>
                {active !== undefined && (
                    <div className={styles.CTAContainer}>
                        <CircleButton
                            active={active}
                            onClick={() => onSelect?.()}
                            className={styles.CTA}
                            ctaTextActivate={`Select`}
                            ctaTextDeactivate={`Unselect`}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default BundleListItem
