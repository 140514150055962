import React from 'react'

import Loading from 'componentsOld/Loading'

import styles from './LoadingWrapper.module.css'

const LoadingWrapper = ({
    loading,
    children,
    className,
}: {
    loading: boolean
    children: React.ReactNode
    className?: string
}) => {
    return loading ? (
        <div className={`${className || ``} ${styles.Container}`}>
            <Loading />
        </div>
    ) : (
        <>{children}</>
    )
}

export default LoadingWrapper
