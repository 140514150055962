import { User } from 'configcat-common'
import { useCallback, useEffect, useState } from 'react'

import { configCatClient } from 'config/ConfigCat'
import useUserState from 'hooks/useUserState'

export enum Flags {
    USE_OLD_DASHBOARD = 'useOldDashboard',
}

type FlagType = { [key in Flags]: boolean }

const FlagDefaults: FlagType = Object.entries(Flags).reduce(
    (acc, [, value]) => ({ ...acc, [value]: false }),
    {} as FlagType,
)

const useFlags = () => {
    const { userState } = useUserState()
    const [flags, setFlags] = useState<FlagType>(FlagDefaults)

    const loadFlags = useCallback(async () => {
        if (!userState || !configCatClient) {
            return
        }

        try {
            const allFlagsAsArray = await configCatClient.getAllValuesAsync(
                new User(userState.user.id, userState.user.email),
            )
            const parsedFlags = allFlagsAsArray.reduce(
                (acc, flag) => ({ ...acc, [flag.settingKey]: flag.settingValue }),
                {} as FlagType,
            )
            setFlags(parsedFlags)
        } catch {
            console.error('Failed to load feature flags')
        }
    }, [userState])

    useEffect(() => {
        loadFlags()
    }, [loadFlags])

    return {
        isOldDashboard: flags[Flags.USE_OLD_DASHBOARD],
    }
}

export default useFlags
