import CloseIcon from '@mui/icons-material/Close'
import { experimental_sx as MUISx, styled } from '@mui/system'
import { Button, LuneTheme } from 'lune-ui-lib'
import { SnackbarProvider as NotistackbarProvider } from 'notistack'
import React, { useEffect, useMemo } from 'react'

import useKeyPress from 'hooks/useKeyPress'

// eslint-disable-next-line no-undef
const SnackbarProvider = ({ children }: { children: JSX.Element }) => {
    const { typography, palette, spacing } = LuneTheme
    const escClicked = useKeyPress(['Escape'])
    let snackBarProviderRef: NotistackbarProvider | null

    useEffect(() => {
        if (escClicked && snackBarProviderRef) snackBarProviderRef.closeSnackbar()
    })

    const StyledSnackbarProvider = useMemo(
        () =>
            styled(NotistackbarProvider)(
                MUISx({
                    '&.SnackbarContent-root': {
                        background: palette.Grey900,
                        fontFamily: typography.fontFamily,
                        fontSize: '14px',
                        fontWeight: 'normal',
                        boxSizing: 'border-box',
                        padding: '0',
                        paddingRight: spacing(1),
                        paddingLeft: spacing(2),
                        lineHeight: '17px',
                        borderRadius: '8px',
                        '&.SnackbarItem-variantError': {
                            background: palette.Red500,
                        },
                    },
                    '.SnackbarItem-message': {
                        maxWidth: '200px',
                        wordBreak: 'normal',
                        '.MuiSvgIcon-root': {
                            display: 'none',
                        },
                        a: {
                            color: 'inherit !important',
                            fontStyle: 'normal',
                            textDecoration: 'underline',
                        },
                    },
                }),
            ),
        [],
    )

    return (
        <StyledSnackbarProvider
            dense
            preventDuplicate
            maxSnack={2}
            ref={(snackbar) => {
                snackBarProviderRef = snackbar
            }}
            action={(snackbarKey) => (
                <Button
                    iconButton
                    leftIcon={<CloseIcon sx={{ color: 'white' }} />}
                    variant={'text'}
                    onClick={() => snackBarProviderRef?.closeSnackbar(snackbarKey)}
                />
            )}
        >
            {children}
        </StyledSnackbarProvider>
    )
}

export default SnackbarProvider
