import React from 'react'

import styles from './Loading.module.css'

const Loading = ({ loaderContainerClassName }: { loaderContainerClassName?: string }) => {
    return (
        <div className={`${styles.loaderContainer} ${loaderContainerClassName}`}>
            <div className={styles.loader}>Loading...</div>
        </div>
    )
}

export default Loading
