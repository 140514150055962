import React from 'react'

import GradientBar from 'componentsOld/GradientBar'

import styles from './Whoops404Page.module.css'

const Whoops404 = () => {
    return (
        <>
            {process.env.REACT_APP_USE_LINKS_APP && <GradientBar height="4px" />}
            <div className={styles.Container}>
                <div className={styles.InnerContainer}>
                    <div className={styles.Oops}>404</div>
                    <div className={styles.OverDescription}>
                        We couldn't find the page you're looking for
                    </div>
                    <img className={styles.Marmot} src={`https://assets.lune.co/marmot.jpeg`} />

                    <div className={styles.UnderDescription}>
                        Did you just want to gopher a ride?
                    </div>
                </div>
            </div>
        </>
    )
}

export default Whoops404
