import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { MAIN_CONTENT_CONTAINER_ID } from 'apps/DashboardApp'
import useContainerHeight from 'hooks/useContainerHeight'

import 'react-lazy-load-image-component/src/effects/blur.css'
import styles from './CalculatorBackground.module.css'

const CalculatorBackground = ({ src, contentHeight }: { src: string; contentHeight: number }) => {
    const [overlay, setOverlay] = useState<boolean>(false)

    // This ID will not exist when Calculator App is mounted within a different context
    // Eventually we'll probably want to pass this in as a parameter to the Calculator App
    const parentHeight = useContainerHeight(MAIN_CONTENT_CONTAINER_ID)
    const height = `${Math.max(contentHeight! + 150, parentHeight!)}px` || `100%`

    return (
        <>
            {overlay && (
                <div
                    className={styles.Overlay}
                    style={{
                        height,
                    }}
                />
            )}
            <LazyLoadImage
                height={height}
                width={'100%'}
                afterLoad={() => setOverlay(true)}
                src={src}
                effect="blur"
                wrapperClassName={styles.Background}
                className={styles.Img}
            />
        </>
    )
}

export default CalculatorBackground
