import { OffsetLink } from '@lune-climate/lune'

import { AccountsSelector } from 'componentsOld/AccountsSelector/AccountsSelector'
import { bootstrapAxiosClient, bootstrapAxiosClientNoInterceptors } from 'endpoints/util'
import { IAccount } from 'models/account'
import { IActivity } from 'models/activity'
import { ApiKeyStatus, IApiKey } from 'models/apiKey'
import {
    CreateAccountRequest,
    CreateApiKeyRequest,
    ExecuteOffsetLinkRequest,
    Organisation,
    OrganisationUsers,
    PaginatedOrdersWithAccountInfo,
    PaymentMethodInitiation,
    PaymentMethods,
    UpdateOrganisationRequest,
} from 'models/openDapi'
import { IPaginationQueryParams, IPaginationResult } from 'models/pagination'
import { ProjectV0 } from 'models/project'
import { IUser } from 'models/user'
import { IFormData } from 'viewsOld/Login'

let dapi = bootstrapAxiosClient(`${process.env.REACT_APP_DAPI_URL}`)
let dapiNoInterceptors = bootstrapAxiosClientNoInterceptors(`${process.env.REACT_APP_DAPI_URL}`)

export const bootstrapDapi = () => {
    dapi = bootstrapAxiosClient(`${process.env.REACT_APP_DAPI_URL}`)
    dapiNoInterceptors = bootstrapAxiosClientNoInterceptors(`${process.env.REACT_APP_DAPI_URL}`)
}

export const resetPassword = (data: { password: string; token: string }): Promise<any> =>
    dapi.post(`/users/reset-password`, data)

export const verifyEmail = (token: string) => dapi.get(`/users/email-verification/${token}`)

export const getUserData = () => dapi.get(`/users`)

export const signup = (signupPayload: {
    email: string
    password: string
    firstname: string
    lastname: string
    currency: string
    accountName?: string
    marketingConsent: boolean
    invitationId?: string
}): Promise<{
    user: IUser
    account: IAccount
    token?: string
}> => dapi.post(`/users`, signupPayload)

export const getProjectBySlug = (slug: string): Promise<ProjectV0> =>
    dapi.get(`/projects/by-slug/${slug}`)

export const updateAccount = (name: string, beneficiary: string | undefined): Promise<IAccount> =>
    dapi.patch(`/accounts`, {
        name,
        beneficiary,
    })
export const createAccount = (data: CreateAccountRequest): Promise<IAccount> =>
    dapi.post(`/accounts`, data)

export const getTeamData = (): Promise<IUser[]> => dapi.get(`/accounts/team`)

export const getApiKeys = (): Promise<IApiKey[]> => dapi.get(`/api-keys`)

export const rotateApiKeys = async (apiKeyId: string): Promise<string> => {
    // The response contains fields other than secret. We're only using secret now.
    const response = (await dapi.put(`/api-keys/${apiKeyId}/rotate`)) as { secret: string }
    return response.secret
}

export const updateApiKeyStatus = (apiKeyId: string, status: ApiKeyStatus): Promise<void> =>
    dapi.patch(`/api-keys/${apiKeyId}`, { status })

export const createApiKey = (data: CreateApiKeyRequest): Promise<string> =>
    dapi.post(`/v1/api-keys`, data)

export const deleteApiKey = (apiKeyId: string): Promise<void> =>
    dapi.delete(`/api-keys/${apiKeyId}`)

export const login = (
    data: IFormData,
): Promise<{
    user: IUser
    account: IAccount
    isAdmin: boolean
    token?: string
}> => dapi.post(`/users/login`, data)

export const getActivity = (after?: string): Promise<IPaginationResult<IActivity>> =>
    dapi.get(`/activity${after ? `?after=${after}` : ''}`)

export const requestPasswordReset = (email: string): Promise<IFormData> =>
    dapi.post(`/users/forgot-password`, { email })

export const getUserAccounts = (): Promise<IAccount[]> => dapi.get(`users/accounts`)

export const setUserPrimaryAccountId = (primaryAccountId: number | string): Promise<void> =>
    dapi.post(`/users/primary-account`, { id: primaryAccountId })

export const getUserSettings = (): Promise<any> => dapi.get(`/users/settings`)

export const setUserSettings = (settings: object): Promise<any> =>
    dapi.put('/users/settings', settings)

export const getOffsetLink = (id: string): Promise<OffsetLink> =>
    dapi.get(`/v1/offset-links/${id}`, { withCredentials: true })

export const placeOffsetLinkOrder = (param: ExecuteOffsetLinkRequest) =>
    dapi.post(`/v1/offset-links`, param)

export const getPaymentMethods = (): Promise<PaymentMethods> => dapi.get(`/v1/payment-methods`)

export const deletePaymentMethod = (stripeId: string): Promise<void> =>
    dapi.delete(`/v1/payment-methods/${stripeId}`)

export const createPaymentMethodInitiation = (): Promise<PaymentMethodInitiation> =>
    dapi.post(`/v1/payment-methods`)

export const uploadLogo = (
    fd: FormData,
): Promise<{
    data: {
        url: string
    }
}> =>
    dapiNoInterceptors.post(`/accounts/logo`, fd, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    })

export const getUserOrganisations = (): Promise<Organisation[]> => dapi.get(`/v1/organisations`)
export const getOrganisation = (id: string): Promise<Organisation> =>
    dapi.get(`/v1/organisations/${id}`)
export const getOrganisationUsers = (id: string): Promise<OrganisationUsers> =>
    dapi.get(`/v1/organisations/${id}/users`)
export const updateOrganisation = (
    id: string,
    organisation: UpdateOrganisationRequest,
): Promise<any> => dapi.patch(`/v1/organisations/${id}`, organisation)
export const uploadOrganisationLogo = (
    id: string,
    fd: FormData,
): Promise<{
    data: {
        url: string
    }
}> =>
    dapiNoInterceptors.post(`/v1/organisations/${id}/logo`, fd, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    })

export const promoteOrganisationAdmin = (organisationId: string, userId: string): Promise<any> =>
    dapi.put(`v1/organisations/${organisationId}/users/${userId}/admin`)
export const demoteOrganisationAdmin = (organisationId: string, userId: string): Promise<any> =>
    dapi.delete(`v1/organisations/${organisationId}/users/${userId}/admin`)
export const removeFromOrganisation = (organisationId: string, userId: string): Promise<any> =>
    dapi.delete(`v1/organisations/${organisationId}/users/${userId}`)
export const inviteToOrganisation = (organisationId: string, email: string): Promise<any> =>
    dapi.post(`v1/organisations/${organisationId}/invite`, { email: email })
export const removeInviteFromOrganisation = (
    organisationId: string,
    inviteId: string,
): Promise<any> => dapi.delete(`v1/organisations/${organisationId}/invite/${inviteId}`)

export const getUserOrders = ({
    after,
    limit,
    organisationIds,
    accountIds,
    accountTypes,
}: IPaginationQueryParams & AccountsSelector = {}): Promise<PaginatedOrdersWithAccountInfo> => {
    return dapi.get(`v1/orders`, {
        params: {
            ...(after && { after }),
            ...(limit && { limit }),
            ...(organisationIds && { organisationIds }),
            ...(accountIds && { accountIds }),
            ...(accountTypes && { accountTypes }),
        },
    })
}
