import React from 'react'

import TooltipInfo from 'componentsOld/TooltipInfo'

import styles from './FormField.module.css'

const FormField = ({
    label,
    component,
    description,
    className,
}: {
    label: string
    component: React.ReactNode
    description?: string | React.ReactNode
    className?: string
}) => {
    return (
        <div className={`${styles.Container} ${className || ''}`}>
            <div className={styles.Label}>
                <label>{label}</label>
                {description && (
                    <TooltipInfo iconClassName={styles.Info} description={description} />
                )}
            </div>
            <div className={styles.Comp} data-testid={`FormField-${label}`}>
                {component}
            </div>
        </div>
    )
}

export default FormField
