import { useQuery } from 'react-query'

import { getPaymentMethods } from 'endpoints/dapi'
import { PaymentMethod } from 'models/openDapi'
import { queryKeys } from 'queryKeys'

function usePaymentMethod() {
    const { isLoading, refetch, data: paymentMethod } = useQuery<PaymentMethod>(
        queryKeys.PAYMENT_METHODS_KEY,
        () => getPaymentMethods().then((data) => data[0]),
    )

    return { isLoading, refetch, paymentMethod }
}

export default usePaymentMethod
