import React, { useLayoutEffect, useRef } from 'react'

import styles from './CalculatorProgressBar.module.css'

/**
 * Hack to add defined dynamic keyframes on react component
 * Only used within this component
 * @param style
 */
const injectStyle = (style: string) => {
    const styleElement = document.createElement('style')
    document.head.appendChild(styleElement)
    const styleSheet = styleElement.sheet
    styleSheet?.insertRule(style, styleSheet.cssRules.length)
}

const buildKeyFramesString = (finalWidth: number) =>
    `@keyframes extend {
    from {
        width: 0px;
    }
    to {
        width: ${finalWidth}px;
     }
}`

const CalculatorProgressBar = ({
    pageLabels,
    activePageIndex,
}: {
    pageLabels: string[]
    activePageIndex: number
}) => {
    const [, setSize] = React.useState({ width: 0, height: 0 })
    const refs = useRef(pageLabels.map(() => React.createRef() as any))

    useLayoutEffect(() => {
        const updateSize = () => setSize({ width: window.innerWidth, height: window.innerHeight })
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return (
        <div className={styles.ProgressBarContainer}>
            {pageLabels.map((stage, index) => {
                const isCurrent = index === activePageIndex
                const isCompleted = index < activePageIndex

                if (isCompleted) {
                    injectStyle(
                        buildKeyFramesString(
                            refs.current[index + 1].current?.getBoundingClientRect().left -
                                refs.current[index].current?.getBoundingClientRect().right -
                                64,
                        ),
                    )
                }

                return (
                    <div key={stage} className={styles.Stage} ref={refs.current[index] as any}>
                        <div
                            className={`${styles.Bulb} ${
                                isCurrent ? styles.BulbCurrentStage : ''
                            } ${isCompleted ? 'completed' : ''}`}
                            ref={refs.current[index] as any}
                        />
                        {isCompleted && index !== pageLabels.length - 1 && (
                            <div
                                className={styles.Connection}
                                style={{
                                    animation: `extend 0.7s forwards`,
                                    top: 11,
                                    left: 56,
                                }}
                            />
                        )}
                        <div className={styles.Label}>{stage}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default CalculatorProgressBar
