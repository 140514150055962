import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from './BackButton.module.css'

const BackButton = ({
    onClick,
    className,
    circleClassName,
}: {
    onClick: () => void
    className?: string
    circleClassName?: string
}) => {
    return (
        <div className={`${styles.Container} ${className || ``}`} onClick={onClick}>
            <div className={`${styles.Circle} ${circleClassName || ``}`}>
                <FontAwesomeIcon className={styles.Chevron} icon={faChevronLeft} />
            </div>
            <div className={styles.Text}>Back</div>
        </div>
    )
}

export default BackButton
