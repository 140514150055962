import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import 'componentsOld/Chevron/Chevron.css'

export enum ChevronState {
    DOWN = 'down',
    UP = 'up',
}

const Chevron = ({
    onUp,
    onDown,
    state,
    className,
}: {
    onUp?: () => void
    onDown?: () => void
    state?: ChevronState
    className?: string
}) => {
    const [localState, setLocalState] = useState<ChevronState>(ChevronState.DOWN)

    const onToggle = (e: any) => {
        e.stopPropagation()

        if (localState === ChevronState.DOWN) {
            setLocalState(ChevronState.UP)
            if (onUp) {
                onUp()
            }
        } else {
            setLocalState(ChevronState.DOWN)
            if (onDown) {
                onDown()
            }
        }
    }

    const finalState = state || localState

    return (
        <FontAwesomeIcon
            className={`Chevron ${className}`}
            onClick={state ? undefined : onToggle}
            icon={finalState === ChevronState.DOWN ? faChevronDown : faChevronUp}
        />
    )
}

export default Chevron
