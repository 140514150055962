import React from 'react'

import BackButton from 'componentsOld/BackButton'

import styles from './BundleDesktopLeftPanel.module.css'

const BundleDesktopLeftPanel = ({
    children,
    backgroundImage,
    onBack,
}: {
    children: React.ReactNode
    backgroundImage: string
    onBack: () => void
}) => (
    <div className={styles.LeftContainer} style={{ backgroundImage }}>
        <BackButton
            className={styles.BackButton}
            circleClassName={styles.BackButtonCircle}
            onClick={onBack}
        />
        {children}
    </div>
)

export default BundleDesktopLeftPanel
