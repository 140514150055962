import styles from './PartnerLogo.module.css'

const PartnerLogo = ({ className, url }: { className?: string; url?: string }) => {
    return (
        <div className={`${className || ''} ${styles.Container} `}>
            <img src={url} className={styles.Image} alt={`partnerLogo`} />
        </div>
    )
}

export default PartnerLogo
