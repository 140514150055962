import { Bundle } from '@lune-climate/lune'
import { useHistory, useParams } from 'react-router-dom'

import BackButton from 'componentsOld/BackButton'
import AddBundleRibbon from 'viewsOld/ProjectExplorer/AddBundleRibbon'
import BundleDesktopLeftPanel from 'viewsOld/ProjectExplorer/BundleDesktopLeftPanel'
import ProjectList from 'viewsOld/ProjectExplorer/BundleDetails/ProjectList/ProjectList'
import ProjectMap from 'viewsOld/ProjectExplorer/BundleDetails/ProjectMap'
import SwitchButtonGroup, {
    SwitchViews,
} from 'viewsOld/ProjectExplorer/BundleDetails/SwitchButtonGroup'
import BundleMobileAddRibbonWrapper from 'viewsOld/ProjectExplorer/BundleMobileAddRibbonWrapper'

import styles from './BundleDetails.module.css'

const BundleDetails = ({
    bundle,
    onSelect,
    view,
    onSwitchView,
}: {
    bundle: Bundle
    onSelect: (bundleId: string) => void
    view?: SwitchViews
    onSwitchView: (view: SwitchViews) => void
}) => {
    const { id: offsetLinkId } = useParams<{ id: string }>()
    const history = useHistory()

    const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 100%), url(${bundle?.primaryImage})`

    const addBundleRibbonComp = (
        <AddBundleRibbon bundleName={bundle.name} bundleId={bundle.id} onSelect={onSelect} />
    )

    return (
        <div className={styles.MainContainer}>
            <BundleDesktopLeftPanel
                backgroundImage={backgroundImage}
                onBack={() => history.push(`/${offsetLinkId}`)}
            >
                {addBundleRibbonComp}
            </BundleDesktopLeftPanel>
            <div className={styles.RightContainer}>
                <div className={styles.GradientBackground}>
                    <div className={styles.BlobUpperRight} />
                    <div className={styles.BlobLowerLeft} />
                </div>
                <div
                    className={styles.RightContainerInner}
                    style={view === SwitchViews.MAP ? { height: `100vh` } : undefined}
                >
                    <div className={styles.ControlsContainer}>
                        {/* ⬇️ Mobile only Back button */}
                        <BackButton
                            className={styles.BackButtonMobile}
                            onClick={() => history.push(`/${offsetLinkId}`)}
                        />
                        <div className={styles.SwitchContainer}>
                            <SwitchButtonGroup
                                activeSelection={view || SwitchViews.LIST}
                                onSwitch={onSwitchView}
                            />
                        </div>
                    </div>
                    {view === SwitchViews.LIST && (
                        <div className={styles.ListContainer}>
                            <div className={styles.ListContainerInner}>
                                <div className={styles.Description}>{bundle?.description}</div>
                                <ProjectList bundle={bundle} />
                            </div>
                        </div>
                    )}
                    {view === SwitchViews.MAP && <ProjectMap bundle={bundle} />}
                    <BundleMobileAddRibbonWrapper
                        className={view === SwitchViews.MAP ? styles.FixedPosition : undefined}
                        backgroundImage={backgroundImage}
                    >
                        {addBundleRibbonComp}
                    </BundleMobileAddRibbonWrapper>
                </div>
            </div>
        </div>
    )
}

export default BundleDetails
