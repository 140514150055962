import React from 'react'
import { match, Route, Switch } from 'react-router-dom'

import CalculatorResult from 'apps/CalculatorApp/CalculatorResult'
import CalculatorSelectionMenu, { CalculatorIds } from 'apps/CalculatorApp/CalculatorSelectionMenu'
import CompanyFootprintCalculator from 'apps/CalculatorApp/CompanyFootprintCalculator'
import ElectricityCalculator from 'apps/CalculatorApp/ElectricityCalculator'
import FlightCalculator from 'apps/CalculatorApp/FlightCalculator'
import PurchasesCalculator from 'apps/CalculatorApp/PurchasesCalculator'
import ShippingCalculator from 'apps/CalculatorApp/ShippingCalculator'

export const CALCULATOR_SUBDOMAIN_NAME =
    process.env.REACT_APP_CALCULATOR_SUBDOMAIN_NAME || `emissions-calculator`

const CalculatorApp = ({ match }: { match: match }) => {
    return (
        <Switch>
            <Route path={match.url + '/'} exact component={CalculatorSelectionMenu} />
            <Route path={match.url + '/:id/results'} component={CalculatorResult} />
            <Route path={match.url + `/${CalculatorIds.FLIGHTS}`} component={FlightCalculator} />
            <Route
                path={match.url + `/${CalculatorIds.PURCHASES}`}
                exact
                component={PurchasesCalculator}
            />
            <Route
                path={match.url + `/${CalculatorIds.ELECTRICITY}`}
                exact
                component={ElectricityCalculator}
            />
            <Route
                path={match.url + `/${CalculatorIds.SHIPPING}`}
                exact
                component={ShippingCalculator}
            />
            <Route
                path={match.url + `/${CalculatorIds.COMPANY_FOOTPRINT}`}
                exact
                component={CompanyFootprintCalculator}
            />
        </Switch>
    )
}

export default CalculatorApp
