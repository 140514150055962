import { Bundle } from '@lune-climate/lune'
import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import Bundles from 'viewsOld/ProjectExplorer/Bundles'
import ExploreBundlesDescription from 'viewsOld/ProjectExplorer/ExploreBundlesDescription'
import Footer from 'viewsOld/ProjectExplorer/Footer'
import GradientBlobBackground from 'viewsOld/ProjectExplorer/GradientBlobBackground'
import PartnerLogo from 'viewsOld/ProjectExplorer/PartnerLogo'

import styles from './ProjectExplorer.module.css'

const ProjectExplorer = ({
    bundles,
    onChangeBundleSelection,
    onCalculateWidth,
    onExploreBundle,
    title,
    description,
    partnerLogoUrl,
}: {
    bundles: Bundle[]
    onChangeBundleSelection: (selection: string[]) => void
    onExploreBundle: (bundleId: string) => void
    onCalculateWidth?: (width: number) => any
    title?: string
    description?: string
    partnerLogoUrl?: string | false
}) => {
    const { width, ref } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 0 })
    const [selectedBundleIds, setSelectedBundleIds] = useState<string[]>([])

    useEffect(() => {
        if (width && onCalculateWidth) {
            onCalculateWidth(width)
        }
    }, [onCalculateWidth, width])

    return (
        <>
            <GradientBlobBackground>
                <div>
                    <div className={styles.Container}>
                        {partnerLogoUrl && (
                            <PartnerLogo className={styles.TopPartnerLogo} url={partnerLogoUrl} />
                        )}
                        <div className={styles.ContainerInner} ref={ref}>
                            <div
                                className={`${styles.LeftSection} ${
                                    partnerLogoUrl ? '' : styles.MarginTopMobile
                                }`}
                            >
                                <ExploreBundlesDescription
                                    description={description}
                                    title={title}
                                />
                            </div>
                            <div className={styles.RightSection}>
                                <Bundles
                                    // @ts-ignore
                                    bundles={bundles}
                                    selectedBundleIds={selectedBundleIds}
                                    onSelect={(id: string) =>
                                        setSelectedBundleIds((prev) => {
                                            const newSelection = prev.includes(id)
                                                ? prev.filter((currId) => currId !== id)
                                                : [...prev, id]
                                            onChangeBundleSelection(newSelection)
                                            return newSelection
                                        })
                                    }
                                    onExplore={(id: string) => onExploreBundle?.(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer width={width} partnerLogoUrl={partnerLogoUrl} />
            </GradientBlobBackground>
        </>
    )
}

export default ProjectExplorer
