import { FORM_ERROR } from 'final-form'
import postalCodes from 'postal-codes-js'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'

import Button from 'componentsOld/Button'
import CountryPicker from 'componentsOld/CountryPicker/CountryPicker'
import { FormInput } from 'componentsOld/FieldComponents/FieldComponents'
import FormField from 'componentsOld/FormField'
import Loading from 'componentsOld/Loading'
import { Organisation, UpdateOrganisationRequest } from 'models/openDapi'

import styles from './OrganisationForm.module.css'

export enum FormKeys {
    NAME = 'name',
    BENEFICIARY = 'beneficiary',
    STREET1 = 'street1',
    STREET2 = 'street2',
    POSTCODE = 'postcode',
    CITY = 'city',
    COUNTRY_CODE = 'countryCode',
    VAT = 'vat',
}

interface FormModel extends Record<FormKeys, string> {}

const required = (value: any) => (value ? undefined : 'This is a required field')

const OrganisationForm = ({
    onSubmit,
    initialValues,
    onSuccess,
    readOnly,
}: {
    onSubmit: (data: UpdateOrganisationRequest) => Promise<Organisation | UpdateOrganisationRequest>
    initialValues: FormModel
    onSuccess: (res?: Organisation | UpdateOrganisationRequest) => void
    readOnly: boolean
}) => {
    const onSubmitForm = async (values: UpdateOrganisationRequest) => {
        try {
            const res = await onSubmit({
                name: values.name,
                beneficiary: values.beneficiary,
                street1: values.street1,
                street2: values.street2,
                postcode: values.postcode,
                city: values.city,
                countryCode: values.countryCode,
                vat: values.vat,
            })
            onSuccess(res)
        } catch (response) {
            const msg = `Something went wrong. Please contact support if this happens again.`
            toast(msg, { type: 'error' })
            return {
                [FORM_ERROR]: msg,
            }
        }
    }

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormField
                            label={'Name'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.NAME}
                                    component={FormInput}
                                    validate={required}
                                />
                            }
                            description={`The name of your organisation.`}
                        />
                        <FormField
                            label={'Beneficiary'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.BENEFICIARY}
                                    component={FormInput}
                                    validate={required}
                                />
                            }
                            description={`The official beneficiary of your organisation's orders.`}
                        />
                        <FormField
                            label={'Address Line 1'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.STREET1}
                                    component={FormInput}
                                />
                            }
                            description={`Main street address of the organisation.`}
                        />
                        <FormField
                            label={'Address Line 2'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.STREET2}
                                    component={FormInput}
                                />
                            }
                            description={`Complementary street address of the organisation.`}
                        />
                        <FormField
                            label={'Postcode'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.POSTCODE}
                                    component={FormInput}
                                    validate={(value) => {
                                        if (!value || !values.countryCode) return
                                        const postCodeValidationRes = postalCodes.validate(
                                            values.countryCode,
                                            value,
                                        )
                                        if (postCodeValidationRes !== true)
                                            return postCodeValidationRes
                                    }}
                                />
                            }
                            description={`Postcode of the organisation.`}
                        />
                        <FormField
                            label={'City'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.CITY}
                                    component={FormInput}
                                />
                            }
                            description={`City of the organisation.`}
                        />
                        <Field
                            name={FormKeys.COUNTRY_CODE}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <CountryPicker
                                    disabled={readOnly}
                                    error={meta.touched && meta.error}
                                    value={value}
                                    onChange={(e) => {
                                        if (e) onChange(e.value)
                                    }}
                                    label={'Country'}
                                />
                            )}
                        />
                        <FormField
                            label={'VAT'}
                            component={
                                <Field
                                    disabled={readOnly}
                                    name={FormKeys.VAT}
                                    component={FormInput}
                                />
                            }
                            description={`VAT of the organisation.`}
                        />
                        {!readOnly && (
                            <div className={styles.Submit}>
                                <Button
                                    data-testid={`SubmitBtn`}
                                    type="submit"
                                    disabled={submitting}
                                >
                                    {submitting ? <Loading /> : 'Update Organisation'}
                                </Button>
                            </div>
                        )}
                    </form>
                )
            }}
        />
    )
}

export default OrganisationForm
