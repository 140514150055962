import { Bundle } from '@lune-climate/lune'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ProjectTypeTag from 'componentsOld/ProjectTypeTag'
import useQuery from 'hooks/useQuery'
import { truncateStringWithoutCutWords } from 'utils'

import styles from './ProjectList.module.css'

const ProjectList = ({ bundle }: { bundle: Bundle }) => {
    const { id: offsetLinkId } = useParams<{ id: string }>()
    const bundleId = useQuery('bundleId')
    const history = useHistory()
    const onSelect = useCallback((id) => {
        history.push({
            pathname: `/${offsetLinkId}/explore/project/${id}`,
            search: `?bundleId=${bundleId}`,
        })
    }, [])

    return (
        <div className={styles.BundleListContainer}>
            {bundle?.projects.map((p) => (
                <div key={p.id} className={styles.BundleListItem} onClick={() => onSelect(p.id)}>
                    <div className={styles.ProjectMetaContainer}>
                        <div className={styles.CountryName}>{p.countryName}</div>
                        <div className={styles.ProjectName}>{p.name}</div>
                        <div
                            className={styles.ProjectDescription}
                        >{`${truncateStringWithoutCutWords(p.description, 140)}...`}</div>
                        <ProjectTypeTag projectType={p.projectType} />
                    </div>
                    <div
                        className={styles.ProjectThumbnail}
                        style={{
                            backgroundImage: `url(${p.thumbnailImage})`,
                        }}
                    />
                </div>
            ))}
        </div>
    )
}

export default ProjectList
