import { maxBy, minBy } from 'lodash'
import WebMercatorViewport from 'viewport-mercator-project'

interface MarkerType {
    latitude: number
    longitude: number
}

export const getMinOrMax = (
    markers: MarkerType[],
    minOrMax: 'max' | 'min',
    latOrLng: 'latitude' | 'longitude',
): number | undefined => {
    if (minOrMax === 'max') {
        return maxBy(markers, (value) => value?.[latOrLng])?.[latOrLng]
    } else {
        return minBy(markers, (value) => value?.[latOrLng])?.[latOrLng]
    }
}

export const getBounds = (markers: MarkerType[]): [[number, number], [number, number]] => {
    const maxLat = getMinOrMax(markers, 'max', 'latitude')
    const minLat = getMinOrMax(markers, 'min', 'latitude')
    const maxLng = getMinOrMax(markers, 'max', 'longitude')
    const minLng = getMinOrMax(markers, 'min', 'longitude')

    const southWest = [minLng || 0, minLat || 0] as [number, number]
    const northEast = [maxLng || 0, maxLat || 0] as [number, number]
    return [southWest, northEast]
}

interface UseMapProps {
    width: number
    height: number
    markers: MarkerType[]
}
export const useMap = ({ width, height, markers }: UseMapProps) => {
    const bounds = getBounds(markers)

    const viewport = new WebMercatorViewport({
        width,
        height,
    }).fitBounds(bounds)

    return {
        bounds: {
            ...viewport,
            zoom: viewport.zoom > 20 || markers.length < 2 ? 3 : viewport.zoom,
        },
    }
}
