import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import styles from './GradientBlobBackground.module.css'

const GradientBlobBackground = ({
    children,
    containerClassName,
}: {
    children: React.ReactNode
    containerClassName?: string
}) => {
    const { height: contentHeight, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 0,
    })

    return (
        <>
            <div
                className={styles.BlobContainer}
                style={{ overflow: `hidden`, height: contentHeight }}
            >
                <div className={`${styles.Container} ${containerClassName || ''}`}>
                    <div style={{ zIndex: 1 }}>
                        <div className={styles.Blob1} />
                        <div className={styles.Blob2} />
                    </div>
                </div>
            </div>
            <div className={styles.ChildWrapper} ref={ref}>
                {children}
            </div>
        </>
    )
}

export default GradientBlobBackground
