import { Bundle } from '@lune-climate/lune'
import mapboxgl from 'mapbox-gl'
import React from 'react'
import ReactMapGL from 'react-map-gl'

import ProjectMarker from 'viewsOld/ProjectExplorer/BundleDetails/ProjectMap/ProjectMarker'

import { useMap } from './useMap'

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './ProjectMap.module.css'

// Workaround to mapbox/create-react-app transpiling issue:
// https://github.com/mapbox/mapbox-gl-js/issues/10173
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const ProjectMap = ({ bundle }: { bundle: Bundle }) => {
    const { bounds } = useMap({
        width: 400,
        height: 400,
        markers: bundle.projects.map((p) => ({
            longitude: p.longitude,
            latitude: p.latitude,
        })),
    })

    const [viewport, setViewport] = React.useState({
        ...bounds,
    })

    return (
        <div id={`mapContainer`} className={styles.Container}>
            <ReactMapGL
                className={styles.Map}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                mapStyle={`mapbox://styles/mapbox/outdoors-v11`}
                maxZoom={20}
                {...viewport}
                height={`100%`}
                width={`100%`}
                onViewportChange={setViewport}
            >
                {bundle.projects.map((p) => (
                    <span key={p.id}>
                        <ProjectMarker project={p} />
                    </span>
                ))}
            </ReactMapGL>
        </div>
    )
}

export default ProjectMap
