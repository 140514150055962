import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from './CircleButton.module.css'

const CircleButton = ({
    active,
    onClick,
    ctaTextActivate,
    ctaTextDeactivate,
    className,
}: {
    active: boolean
    onClick: () => void
    ctaTextActivate?: string
    ctaTextDeactivate?: string
    className?: string
}) => {
    return (
        <div
            className={`${styles.Container} ${className || ''}`}
            onClick={(e) => {
                e.stopPropagation()
                onClick()
            }}
        >
            {ctaTextActivate && (
                <div className={styles.Text}>{active ? ctaTextDeactivate : ctaTextActivate}</div>
            )}
            {!active && (
                <div className={styles.Inner}>
                    <FontAwesomeIcon icon={faPlus} className={styles.Plus} />
                </div>
            )}
            {active && (
                <div className={styles.Inner}>
                    <FontAwesomeIcon icon={faTimes} className={styles.Plus} />
                </div>
            )}
        </div>
    )
}

export default CircleButton
