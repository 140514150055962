import { Layout } from 'lune-ui-lib'
import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { toast, ToastContainer as Toast } from 'react-toastify'

import CalculatorApp from 'apps/CalculatorApp'
import MainNavigation from 'components/MainNavigation'
import SettingsNavigation from 'components/SettingsNavigation'
import Header from 'componentsOld/Header'
import Sidebar from 'componentsOld/Sidebar'
import useFlags from 'hooks/useFlags'
import useTitle from 'hooks/useTitle'
import useToken from 'hooks/useToken'
import Activity from 'pages/ActivityPage'
import * as OldApiKeys from 'pages/ApiKeysPage'
import BundlePage from 'pages/BundlePage'
import CreateOrderPage from 'pages/CreateOrderPage'
import * as OldDashboard from 'pages/DashboardPage'
import OffsetLinkDetailsPage from 'pages/OffsetLinkDetailsPage'
import OffsetLinksPage from 'pages/OffsetLinksPage'
import OrderDetailsPage from 'pages/OrderDetailsPage'
import OrdersPage from 'pages/OrdersPage'
import OrderSuccessPage from 'pages/OrderSuccessPage'
import ProjectDetailsPage from 'pages/ProjectDetailsPage'
import ProjectsPage from 'pages/ProjectsPage'
import Settings from 'pages/SettingsPage'
import UserOrdersPage from 'pages/UserOrdersPage'
import Webhooks from 'pages/WebhooksPage'
import Whoops404Page from 'pages/Whoops404Page'
import PrivateRoute from 'PrivateRoute'
import ForgotPassword from 'views/Account/ForgotPassword'
import Login from 'views/Account/Login'
import Logout from 'views/Account/Logout'
import ResetPassword from 'views/Account/ResetPassword'
import Signup from 'views/Account/Signup'
import VerifyEmailConfirmation from 'views/Account/VerifyEmailConfirmation'
import BuyOffsets from 'views/BuyOffsets'
import Dashboard from 'views/Dashboard'
import Developers from 'views/Developers'
import CreateApiKey from 'views/Developers/CreateApiKey'
import Orders from 'views/Orders'
import Order from 'views/Orders/Order/Order'
import Project from 'views/Projects/Project'
import ProjectBundles from 'views/Projects/ProjectBundles'
import Account from 'views/Settings/Account'
import Billing from 'views/Settings/Billing'
import ClientAccount from 'views/Settings/ClientAccount'
import ClientAccounts from 'views/Settings/ClientAccounts'
import CreateAccount from 'views/Settings/CreateAccount'
import OrganisationSettings from 'views/Settings/OrganisationSettings'

import 'react-toastify/dist/ReactToastify.min.css'
import 'apps/DashboardApp/DashboardApp.css'

export const MAIN_CONTENT_CONTAINER_ID = `mainContent`

function App() {
    const { isOldDashboard } = useFlags()
    const { token, setToken } = useToken()
    useTitle('Lune - Dashboard')
    const location = useLocation()

    // switchAccount set to true when switching account via AccountPortalMenu comp
    useEffect(() => {
        if (sessionStorage.switchAccount === 'true') {
            sessionStorage.switchAccount = 'false'
            toast(`✔️ Account successfully switched!`, { type: 'success' })
        }
    }, [])

    useEffect(() => {
        if (!location) {
            return
        }
        mixpanel.track('PageView', {
            path: location.pathname,
        })
        // @ts-ignore
        window.Appcues?.page()
    }, [location])

    const ToastContainer = (
        <Toast
            toastStyle={{ marginTop: `8px` }}
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
        />
    )

    const settingsPagePath = '/settings'
    const projectsPagePath = '/'

    return (
        <Switch>
            <Route path="/login" render={() => <Login setToken={setToken} />} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/logout" render={() => <Logout setToken={setToken} />} />
            <Route path="/signup" render={() => <Signup setToken={setToken} />} />
            <Route path="/verify-email" component={VerifyEmailConfirmation} />

            {!isOldDashboard && (
                <PrivateRoute path={settingsPagePath} token={token}>
                    <Layout sideBar={<SettingsNavigation />}>
                        <Switch>
                            <Route
                                exact
                                path={`${settingsPagePath}`}
                                component={OrganisationSettings}
                            />
                            <Route path={`${settingsPagePath}/billing`} component={Billing} />
                            <Redirect
                                from={`${settingsPagePath}/payment-methods`}
                                to={`${settingsPagePath}/billing${location.search}`}
                            />

                            <Route
                                exact
                                path={`${settingsPagePath}/client-accounts`}
                                component={ClientAccounts}
                            />
                            <Route
                                exact
                                path={`${settingsPagePath}/client-accounts/:accountId`}
                                component={ClientAccount}
                            />
                            <Route
                                exact
                                path={`${settingsPagePath}/accounts/new`}
                                component={CreateAccount}
                            />
                            <Route
                                exact
                                path={`${settingsPagePath}/accounts/:accountId`}
                                component={Account}
                            />
                        </Switch>
                    </Layout>
                </PrivateRoute>
            )}
            {!isOldDashboard && (
                <PrivateRoute path={projectsPagePath} token={token}>
                    <Layout sideBar={<MainNavigation />}>
                        <Switch>
                            <Route exact path={`${projectsPagePath}`} component={ProjectBundles} />
                            <Route
                                exact
                                path={`${projectsPagePath}dashboard`}
                                component={Dashboard}
                            />
                            <Route exact path={`${projectsPagePath}orders`} component={Orders} />
                            <Route
                                exact
                                path={`${projectsPagePath}orders/:orderId`}
                                component={Order}
                            />
                            <Route
                                exact
                                path={`${projectsPagePath}developers`}
                                component={Developers}
                            />
                            <Route
                                exact
                                path={`${projectsPagePath}developers/new-api-key`}
                                component={CreateApiKey}
                            />
                            <Route exact path={`${projectsPagePath}order`} component={BuyOffsets} />
                            <Route
                                exact
                                path={`${projectsPagePath}projects/:projectId`}
                                component={Project}
                            />
                        </Switch>
                    </Layout>
                </PrivateRoute>
            )}
            <PrivateRoute exact path="*" token={token}>
                <Header />
                <div className={`AppAndSidebar`}>
                    <Sidebar />
                    <div className={`MainAppContainer`} id={MAIN_CONTENT_CONTAINER_ID}>
                        <Switch>
                            {isOldDashboard && (
                                <Route path="/" exact component={OldDashboard.default} />
                            )}
                            <Route path="/projects/:projectSlug" component={ProjectDetailsPage} />
                            <Route path="/bundles/:bundleId" component={BundlePage} />
                            <Route path="/projects" component={ProjectsPage} />
                            <Route path="/emissions-calculator" component={CalculatorApp} />
                            <Route path="/orders" exact component={OrdersPage} />
                            <Route path="/all-orders" exact component={UserOrdersPage} />
                            <Route path="/orders/:orderId" component={OrderDetailsPage} />
                            <Route path="/activity" component={Activity} />
                            <Route path="/api-keys/:activeTab?" component={OldApiKeys.default} />
                            <Route path="/webhooks/:activeTab?" component={Webhooks} />
                            <Route path="/offset-links/:id" component={OffsetLinkDetailsPage} />
                            <Route path="/offset-links" component={OffsetLinksPage} />
                            {isOldDashboard && (
                                <Route
                                    path="/settings/:activeTab?/:activeSubTab?"
                                    component={Settings}
                                />
                            )}
                            <Route path="/order-success/:orderId" component={OrderSuccessPage} />
                            <Route path="/order" component={CreateOrderPage} />
                            <Route path="*" component={Whoops404Page} />
                        </Switch>
                    </div>
                </div>
                {ToastContainer}
            </PrivateRoute>
        </Switch>
    )
}

export default function DashboardApp() {
    return (
        <Router>
            <App />
        </Router>
    )
}
