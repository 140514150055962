import SliderComp, { SliderProps, SliderTooltip } from 'rc-slider'
import React from 'react'

const { Handle } = SliderComp

const Slider = (props: Partial<SliderProps> & { unit?: string }) => {
    const handle = (handleProps: any) => {
        const { value, index, ...restProps } = handleProps
        return (
            <SliderTooltip
                zIndex={2}
                prefixCls="rc-slider-tooltip"
                overlay={`${value} ${props.unit ? props.unit : ''}`}
                visible
                placement="bottom"
                key={index}
                overlayStyle={{
                    boxShadow: `none`,
                    border: `none`,
                    background: `none`,
                    fontFamily: `Helvetica Now Display`,
                }}
                overlayInnerStyle={{
                    boxShadow: `none`,
                    border: `none`,
                    background: `none`,
                    color: 'black',
                    fontFamily: `Helvetica Now Display`,
                }}
            >
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        )
    }

    return (
        <SliderComp
            maximumTrackStyle={{
                border: 'none',
                background: '#e6e6e6',
                height: '8px',
            }}
            minimumTrackStyle={{
                background: 'black',
                height: '8px',
                border: 'none',
            }}
            handleStyle={{
                height: '24px',
                width: '24px',
                backgroundColor: 'black',
                border: 'none',
                position: 'relative',
                top: '-2px',
                outline: 'none',
            }}
            min={props.min}
            max={props.max}
            step={1}
            defaultValue={props.defaultValue}
            handle={handle}
            value={props.value}
            onChange={props.onChange}
        />
    )
}

export default Slider
