import React from 'react'

import styles from './SimpleGrid.module.css'

const SimpleGrid = ({ children }: { children: React.ReactNode[] }) => {
    return <div className={styles.Grid}>{children}</div>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
SimpleGrid.Field = ({ label, value }: { label: string; value: React.ReactNode }) => {
    return (
        <>
            <div>{label}:</div>
            <span className={styles.Highlight}>{value}</span>
        </>
    )
}

export default SimpleGrid
