import { EmissionEstimate } from '@lune-climate/lune'
import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { useResizeDetector } from 'react-resize-detector'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CALCULATOR_SUBDOMAIN_NAME } from 'apps/CalculatorApp/CalculatorApp'
import CalculatorBackButton from 'apps/CalculatorApp/CalculatorBackButton'
import CalculatorBackground from 'apps/CalculatorApp/CalculatorBackground'
import CalculatorButton from 'apps/CalculatorApp/CalculatorButton'
import CalculatorProgressBar from 'apps/CalculatorApp/CalculatorProgressBar'
import { notify } from 'bugsnag'
import Loading from 'componentsOld/Loading'
import { getCalcQueryKeyById } from 'queryKeys'

import styles from './CalculatorWizardForm.module.css'

const CalculatorWizardForm = <PayloadModel,>({
    children,
    onSubmit,
    initialValues,
    title,
    id,
}: {
    children: React.ReactNode[]
    onSubmit: (payload: PayloadModel) => Promise<EmissionEstimate>
    initialValues: PayloadModel
    title: string
    id: string
}) => {
    const client = useQueryClient()
    const queryKey = getCalcQueryKeyById(id)
    const cachedData = client.getQueryData(queryKey) as
        | {
              input: Record<string, any>
              activePageIndex: number
          }
        | undefined

    const history = useHistory()
    const [activePageIndex, setActivePageIndex] = React.useState(cachedData?.activePageIndex || 0)
    const { height: contentHeight, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 0,
    })

    const allPages = useMemo(() => React.Children.toArray(children) as any, [children])
    const activePage = allPages[activePageIndex]

    const next = () => {
        const index = Math.min(activePageIndex + 1, children.length - 1)
        client.setQueryData(queryKey, (prev: any) => ({
            ...prev,
            activePageIndex: index,
        }))
        setActivePageIndex(index)
    }

    const previous = () => {
        const index = Math.max(activePageIndex - 1, 0)
        client.setQueryData(queryKey, (prev: any) => ({
            ...prev,
            activePageIndex: index,
        }))
        setActivePageIndex(index)
    }

    const validate = (values: PayloadModel) =>
        allPages[activePageIndex]?.props?.validate?.(values) || {}

    const isLastPage = activePageIndex === allPages.length - 1

    const handleSubmit = async (values: PayloadModel) => {
        client.setQueryData(queryKey, (prev: any) => ({
            ...prev,
            input: values,
        }))
        if (isLastPage) {
            const output = await onSubmit(values).catch((e) => {
                toast(`Please contact support if this happens again.`, {
                    type: 'error',
                })
                notify(e)
            })
            if (!output) {
                return
            }
            client.setQueryData(queryKey, (prev: any) => ({
                ...prev,
                output,
            }))
            history.push(`/${CALCULATOR_SUBDOMAIN_NAME}/${id}/results`)
        } else {
            next()
        }
    }

    return (
        <div ref={ref}>
            <CalculatorBackground
                src={activePage.props.backgroundImageSrc}
                contentHeight={contentHeight!}
            />
            <div
                style={{
                    minHeight: `1149px`,
                    overflow: `auto`,
                }}
            >
                <CalculatorBackButton
                    onClick={
                        activePageIndex === 0
                            ? () => history.push(`/${CALCULATOR_SUBDOMAIN_NAME}/`)
                            : previous
                    }
                />
                <div className={styles.CalculatorMainTitle}>{title}</div>
                <CalculatorProgressBar
                    activePageIndex={activePageIndex}
                    pageLabels={allPages.map(
                        (p: { props: { progressBarLabel: string } }) => p.props.progressBarLabel,
                    )}
                />
                <Form
                    initialValues={cachedData?.input || initialValues}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, submitting, values }) => (
                        <div className={styles.FormContainerWrapper}>
                            <form
                                style={{ position: `relative`, zIndex: 2 }}
                                onSubmit={handleSubmit}
                            >
                                <>
                                    <div className={styles.ActivePageWrapper}>
                                        <div className={styles.PageTitle}>
                                            {activePage.props.title}
                                        </div>
                                        {activePage.props.subtitle && (
                                            <div className={styles.PageSubtitle}>
                                                {activePage.props.subtitle}
                                            </div>
                                        )}
                                        {typeof activePage.props.children === 'function'
                                            ? activePage.props.children(values)
                                            : activePage.props.children}
                                    </div>
                                    <div className={styles.Buttons}>
                                        {!isLastPage && (
                                            <CalculatorButton type="submit" text={'Continue'} />
                                        )}
                                        {isLastPage && (
                                            <CalculatorButton
                                                type="submit"
                                                disabled={submitting}
                                                text={
                                                    submitting ? <Loading /> : `Calculate Emissions`
                                                }
                                            />
                                        )}
                                    </div>
                                </>
                            </form>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    )
}

type WizardPage = {
    children: React.ReactNode
    backgroundImageSrc: string
    validate?: (payload: any) => void
    title: string
    subtitle?: React.ReactNode
    progressBarLabel: string
}

CalculatorWizardForm.Page = ({
    children,
    /* eslint-disable */
    validate,
    backgroundImageSrc,
    title,
    subtitle,
    progressBarLabel /* eslint-enable */,
}: WizardPage) => {
    return <div>{children}</div>
}

export default CalculatorWizardForm
