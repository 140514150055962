import React from 'react'

import Button from 'componentsOld/Button'
import GradientBar from 'componentsOld/GradientBar'

import styles from './ErrorPage.module.css'

const ErrorPage = ({ error }: { error: any }) => {
    return (
        <>
            <GradientBar height="4px" />
            <div className={styles.Container}>
                <div className={styles.InnerContainer}>
                    <div className={styles.Oops}>Oops!</div>
                    <div className={styles.OverDescription}>
                        Something went wrong... sorry about that.
                    </div>
                    <div className={styles.Message}>
                        <div>{error?.toString()}</div>
                    </div>
                    <div className={styles.Monkey} />
                    <div className={styles.UnderDescription}>
                        Contact us at <a href="mailto:support@lune.co">support@lune.co</a> if you
                        experience further monkey business!
                    </div>
                </div>
                <div className={styles.Controls}>
                    <Button
                        onClick={() => {
                            document.location.href = '/'
                        }}
                    >
                        Go To Homepage
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ErrorPage
