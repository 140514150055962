import colorLib from '@kurkle/color'
import { LuneTheme } from 'lune-ui-lib'
import moment from 'moment'
import { Chart } from 'react-chartjs-2'
import { Range } from 'react-date-range'

import { OrderType } from 'models/order'
import { formatToCurrency } from 'utils'

export interface Dataset {
    date: string
    quantity: number
    value: number
}

export interface BundleData {
    bundleId: string
    bundleName: string
    timeseries: Dataset[]
}

const { palette, typography } = LuneTheme

// Utils
export const chartColors = [
    '#0099F1',
    '#3BC9F0',
    '#69DFC9',
    '#C4EB9E',
    '#FDF07D',
    '#FFE175',
    '#FFB967',
    '#FF7759',
    '#FF4343',
    '#FF8BD8',
    '#E57EFF',
]

export const transparentize = (value: string, alpha: number) => {
    const a = alpha === undefined ? 0.5 : 1 - alpha
    return colorLib(value).lighten(a).rgbString()
}

export const getGradient = (ctx: any, chartArea: any, color: string) => {
    let width, height, gradient
    const chartWidth = chartArea.right - chartArea.left
    const chartHeight = chartArea.bottom - chartArea.top
    if (!gradient || width !== chartWidth || height !== chartHeight) {
        width = chartWidth
        height = chartHeight
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
        gradient.addColorStop(0, 'rgba(255, 255, 255, .5)')
        gradient.addColorStop(1, transparentize(color, 0.8))
    }

    return gradient
}

export const getDateLabels = (dataset: BundleData[]) => {
    if (!dataset) {
        return []
    }
    return Array.from(
        new Set(
            dataset.reduce((acc: string[], curr) => {
                return [...acc, ...curr.timeseries.map((x: Dataset) => x.date)]
            }, []),
        ),
    )
}

// Custom tooltip
const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')
    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = 'rgba(0, 0, 0)'
        tooltipEl.style.borderRadius = '4px'
        tooltipEl.style.color = 'white'
        tooltipEl.style.opacity = 1
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'
        tooltipEl.style.fontSize = '12px'
        tooltipEl.style.marginTop = '-15px'
        tooltipEl.style.transform = 'translate(-50%, -100%)'
        tooltipEl.style.fontFamily = typography.fontFamily
        tooltipEl.style.minWidth = '100px'

        const caret = document.createElement('span')
        caret.style.width = '8px'
        caret.style.height = '8px'
        caret.style.backgroundColor = 'black'
        caret.style.position = 'absolute'
        caret.style.bottom = '-4px'
        caret.style.left = '0'
        caret.style.right = '0'
        caret.style.margin = 'auto'
        caret.style.zIndex = '-1'
        caret.style.transform = 'rotate(45deg)'

        const dot = document.createElement('span')
        dot.style.width = '8px'
        dot.style.height = '8px'
        dot.style.backgroundColor = 'black'
        dot.style.position = 'absolute'
        dot.style.bottom = '-18px'
        dot.style.left = '0'
        dot.style.right = '0'
        dot.style.margin = 'auto'
        dot.style.borderRadius = '100%'

        const table = document.createElement('table')
        table.style.margin = '0'

        tooltipEl.appendChild(table)
        tooltipEl.appendChild(caret)
        tooltipEl.appendChild(dot)
        chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
}

const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || []
        const bodyLines = tooltip.body.map((b: any) => b.lines)

        const tableHead = document.createElement('thead')

        titleLines.forEach((title: string) => {
            const tr = document.createElement('tr')
            tr.style.borderWidth = '0'
            tr.style.fontSize = '12px'
            tr.style.fontFamily = typography.fontFamily as string

            const th = document.createElement('th')
            th.style.borderWidth = '0'
            th.style.fontSize = '12px'
            const text = document.createTextNode(title)

            th.appendChild(text)
            tr.appendChild(th)
            tableHead.appendChild(tr)
        })

        const tableBody = document.createElement('tbody')
        bodyLines.forEach((body: string) => {
            const span = document.createElement('span')
            span.style.display = 'none' as const

            const tr = document.createElement('tr')
            tr.style.backgroundColor = 'black'
            tr.style.borderWidth = '0'
            tr.style.fontSize = '12px'

            const td = document.createElement('td')
            td.style.borderWidth = '0'
            tr.style.fontSize = '12px'
            tr.style.fontFamily = typography.fontFamily as string

            const text = document.createTextNode(body)

            td.appendChild(span)
            td.appendChild(text)
            tr.appendChild(td)
            tableBody.appendChild(tr)
        })

        const tableRoot = tooltipEl.querySelector('table')

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove()
        }
        // Add new children
        tableRoot.appendChild(tableHead)
        tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.font = typography.fontFamily
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
}

// Plugins
export const legendBottomMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
        const fitValue = chart.legend.fit
        chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)()
            this.height += 30
            return this.height
        }
    },
}
export const chartAreaBorder = {
    id: 'chartAreaBorder',
    afterDatasetDraw(chart: Chart) {
        const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
        } = chart
        ctx.save()

        ctx.strokeStyle = '#e9e9e9'
        ctx.strokeRect(right - 1, top, 0, height)
        ctx.strokeRect(left - 1, top, width, 0)
        ctx.strokeRect(left - 1, bottom, width, 0)
        ctx.strokeRect(left - 1, top, 0, height)
        ctx.restore()
    },
}

// Chart options
export const chartOptions = (
    type: OrderType,
    chartData: any,
    currency?: string,
    dateRange?: Range,
) => {
    const rangeDuration = moment.duration(moment(dateRange?.endDate).diff(dateRange?.startDate))
    const dateUnit =
        !dateRange?.endDate || !dateRange?.startDate || rangeDuration.asDays() > 364
            ? ('month' as const)
            : rangeDuration.asMonths() > 1
            ? ('week' as const)
            : ('day' as const)
    return {
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
        },
        elements: {
            point: {
                radius:
                    chartData?.datasets?.length === 1 && chartData?.labels?.length === 1 ? 5 : 0,
                hoverRadius: 0,
            },
        },
        layout: {
            autoPadding: false,
            padding: {
                left: -10,
            },
        },
        scales: {
            y: {
                stacked: true,
                grid: {
                    tickLength: 0,
                    drawBorder: false,
                    color: ['#e9e9e9'],
                    tickBorderDashOffset: 10,
                },
                ticks: {
                    mirror: true,
                    z: 9 as const,
                    padding: 10 as const,
                    showLabelBackdrop: true,
                    backdropColor: palette.White,
                    backdropPadding: 4,
                    textStrokeWidth: 10,
                    color: palette.Grey900,
                    font: {
                        family: typography.fontFamily,
                    },
                    callback: (value: any, index: any, ticks: any) => {
                        if (index === 0 || index === ticks.length - 1) {
                            return null
                        } else if (index === ticks.length - 2) {
                            if (type === OrderType.VALUE) {
                                return currency ? formatToCurrency(value, currency) : value
                            } else {
                                return value + ' tCO2'
                            }
                        } else {
                            return value
                        }
                    },
                },
            },
            x: {
                ticks: {
                    font: {
                        family: typography.fontFamily,
                    },
                    color: palette.Grey900,
                },
                type: 'time' as const,
                time: {
                    unit: dateUnit,
                    tooltipFormat: 'YYYY-MM-DD' as const,
                    displayFormats: {
                        month: 'MMM, YYYY',
                        week: 'DD MMM',
                        day: 'DD MMM',
                    },
                },
                grid: {
                    tickLength: 10,
                    tickColor: palette.White,
                    drawBorder: false,
                    color: ['transparent', '#e9e9e9'],
                },
            },
        },
        plugins: {
            paddingBelowLegends: '30px',
            legend: {
                position: 'top' as const,
                align: 'start' as const,
                labels: {
                    font: {
                        family: typography.fontFamily,
                    },
                    usePointStyle: true,
                    padding: 16 as const,
                    textAlign: 'left' as const,
                    color: palette.Grey900,
                },
            },

            tooltip: {
                enabled: false,
                position: 'nearest' as const,
                external: externalTooltipHandler,
            },
        },
    }
}
