import React, { useState } from 'react'

import './Tabs.css'

const Tabs = ({ children }: { children: React.ReactNode[] }) => {
    const titles = children.map((c: any) => c.props.title)
    const [active, setActive] = useState<string>(titles[0])

    return (
        <div className="tab-content-wrapper">
            <div className="tabs">
                {titles.map((title) => (
                    <div
                        key={title}
                        className={`tab${title === active ? ' tab-active' : ''}`}
                        onClick={() => setActive(title)}
                    >
                        {title}
                    </div>
                ))}
            </div>
            {children.find((c: any) => c.props.title === active)}
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
Tabs.Tab = ({ children, title }: { children: React.ReactNode; title: string }) => {
    return <>{children}</>
}

export default Tabs

export const Tab = Tabs.Tab
