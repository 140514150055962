import { AccountType } from 'models/account'

export enum ApiKeyStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface IApiKey {
    id: string
    accountId: string
    accountName: string
    accountType: AccountType
    name: string
    key: string
    status: ApiKeyStatus
    createdAt: string
    maskedSecret: string
}
