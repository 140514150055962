import { Stepper, StepState } from 'lune-ui-lib'
import React, { FC } from 'react'

export enum Step {
    RECEIVED,
    PLACED,
    PAID,
    COMPLETE,
    FAILED,
}

const Order: FC<{ orderStatusStep: Step }> = ({ orderStatusStep }) => {
    return (
        <>
            {orderStatusStep === Step.FAILED ? (
                <Stepper
                    steps={[
                        {
                            label: 'Received',
                            state: StepState.COMPLETED,
                        },
                        {
                            label: 'Failed',
                            state: StepState.CANCELED,
                        },
                    ]}
                    sx={{
                        width: '210px',
                    }}
                />
            ) : (
                <Stepper
                    steps={[
                        {
                            label: 'Received',
                            state: StepState.COMPLETED,
                        },
                        {
                            label: 'Placed',
                            state:
                                orderStatusStep === Step.RECEIVED
                                    ? StepState.ACTIVE
                                    : StepState.COMPLETED,
                        },
                        {
                            label: 'Paid',
                            state:
                                orderStatusStep === Step.PLACED
                                    ? StepState.ACTIVE
                                    : orderStatusStep < Step.PLACED
                                    ? StepState.PENDING
                                    : StepState.COMPLETED,
                        },
                        {
                            label: orderStatusStep !== Step.COMPLETE ? 'Retiring' : 'Completed',
                            state:
                                orderStatusStep === Step.PAID
                                    ? StepState.ACTIVE
                                    : orderStatusStep < Step.PAID
                                    ? StepState.PENDING
                                    : StepState.COMPLETED,
                        },
                    ]}
                    sx={{
                        width: '500px',
                    }}
                />
            )}
        </>
    )
}

export default Order
