import { IConfigCatClient } from 'configcat-common'
import { createClientWithManualPoll, createConsoleLogger } from 'configcat-js'

// This is our Feature Flag SaaS configuration
const CONFIG_CAT_API_KEY = 'DC3aCP3bz0SJ8PkIwuc6uA/8BtubNdwGkm2u0vDp0nj3g'
const options =
    process.env.NODE_ENV === 'development'
        ? {
              logger: createConsoleLogger(3),
          }
        : {}

export let configCatClient: IConfigCatClient
try {
    configCatClient = createClientWithManualPoll(CONFIG_CAT_API_KEY, options)
} catch {
    console.error(`Failed to create ConfigCat client.`)
}
