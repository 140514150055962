import React from 'react'

import './Button.css'

export interface IButtonProps {
    children: React.ReactNode
    className?: string
    disabled?: boolean
    onClick?: () => any
    small?: boolean
    type?: 'submit'
    testId?: string
}

export default function Button({
    children,
    className,
    onClick,
    disabled,
    small,
    ...rest
}: IButtonProps) {
    return (
        <button
            {...(onClick && { onClick })}
            className={`btn primary-btn ${className} ${small ? `btn-small` : ``}`}
            disabled={disabled ?? false}
            {...rest}
        >
            {children}
        </button>
    )
}

export function SecondaryButton({
    children,
    className,
    onClick,
    disabled,
    small,
    testId,
}: IButtonProps) {
    return (
        <button
            {...(onClick && { onClick })}
            className={`btn secondary-btn ${className} ${small ? `btn-small` : ``}`}
            data-testid={testId}
            disabled={disabled ?? false}
        >
            {children}
        </button>
    )
}
