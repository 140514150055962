import { CloudProvider, Diet } from '@lune-climate/lune'
import { camelCase, capitalize, startCase, upperFirst } from 'lodash'
import React from 'react'

import styles from 'apps/CalculatorApp/CalculatorJsonSummary/CalculatorJsonSummary.module.css'
import { formatToCurrency } from 'utils'

/**
 * Flatten object to display in a table
 * Amount/unit nodes are merged into a single row
 * @param input
 */
export const flattenObjAndCombineUnits = (input: Record<string, any>) => {
    const result: any = {}
    for (const key in input) {
        const curr = input[key]
        if (typeof curr === 'object' && !Array.isArray(curr) && (curr.value || curr.amount)) {
            // Nodes containing amount/unit or amount/currency are merged in a single entry
            const values = Object.values(curr)
            const amount = values[0] as string
            let unit = values[1] as string
            if (Object.keys(curr).includes(`currency`)) {
                result[key] = formatToCurrency(amount, unit)
            } else {
                unit = ` ${unit}`
                unit = unit === ` square_meters` ? `m²` : unit
                unit = unit === ` square_feet` ? `ft²` : unit
                result[key] = `${amount}${unit?.length > 4 ? startCase(unit) : unit}`
            }
        } else if (typeof curr === 'object' && !Array.isArray(curr)) {
            const temp = flattenObjAndCombineUnits(curr)
            for (const j in temp) {
                result[key + ' - ' + j] = temp[j]
            }
        } else {
            if (curr === true) {
                result[key] = 'Yes'
            } else if (Object.values(CloudProvider).includes(curr) && curr.length < 4) {
                result[key] = curr.toUpperCase()
            } else if (Object.values(Diet).includes(camelCase(curr) as any)) {
                result[key] = capitalize(startCase(curr).toLowerCase())
            } else if (key === `electricityConsumption`) {
                result[key] = `${curr} kWh`
            } else if (key === `garbage`) {
                result[key] = `${curr} kg`
            } else {
                result[key] = curr
            }
        }
    }
    return result
}

const CalculatorJsonSummary = ({ input }: { input: Record<string, any> }) => (
    <div style={{ width: `100%` }}>
        {Object.entries(flattenObjAndCombineUnits(input))
            .filter(([, v]) => v)
            .map(([k, v]: any) => (
                <div key={k}>
                    <CalculatorJsonSummary.Field
                        label={capitalize(startCase(k).toLowerCase())}
                        value={v.length > 3 ? upperFirst(v) : v}
                    />
                </div>
            ))}
    </div>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
CalculatorJsonSummary.Field = ({ label, value }: { label: string; value: React.ReactNode }) => {
    return (
        <div className={styles.Grid}>
            <div className={styles.Key}>{label}:</div>
            <span className={styles.Value}>{value}</span>
        </div>
    )
}

export default CalculatorJsonSummary
