import { OffsetLink } from '@lune-climate/lune'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { LinksContext, LOCAL_STORAGE_SELECTED_BUNDLE_KEY } from 'apps/LinksApp/LinksApp'
import ProjectExplorer from 'viewsOld/ProjectExplorer'

const LinksBundleSelection = () => {
    const { data, setSelectedBundleIds, setMainContentWidth } = useContext(LinksContext)

    const history = useHistory()
    const { id: offsetLinkId } = useParams<{ id: string }>()

    return (
        <ProjectExplorer
            onChangeBundleSelection={(selected) => {
                setSelectedBundleIds(selected)
                localStorage.setItem(LOCAL_STORAGE_SELECTED_BUNDLE_KEY, JSON.stringify(selected))
                history.push(`/${offsetLinkId}/confirm/`)
            }}
            onCalculateWidth={(width) => setMainContentWidth(width)}
            bundles={data?.bundles!}
            title={data?.title}
            description={(data as OffsetLink)?.description}
            onExploreBundle={(bundleId) => {
                history.push(`/${offsetLinkId}/explore/List/?bundleId=${bundleId}`)
            }}
            partnerLogoUrl={data?.useLogo && data?.logo}
        />
    )
}

export default LinksBundleSelection
