export enum queryKeys {
    GET_USER = `getUser`,
    GET_ACCOUNTS = `getAccounts`,
    GET_BUNDLE_SELECTION = `getBundleSelection`,
    GET_USER_ORGANISATIONS = `getUserOrganisations`,
    EMISSION_RESULTS_QUOTE = `emissionResultsQuote`,
    EMISSION_CALCULATION_RESULTS = `emissionsResults`,
    GET_ORGANISATION = 'getOrganisation',
    GET_ORGANISATION_USERS = 'getOrganisationUsers',
    GET_BUNDLES = 'getBundles',
    GET_BUNDLE_MIXES = 'getBundleMixes',
    GET_API_KEYS = 'getApiKeys',
    PAYMENT_METHODS_KEY = `paymentMethods`,
    GET_ACTIVE_ACCOUNT_FOR_TEST_SWITCH = `getActiveAccountForTestSwitch`,
    GET_ORDERS = `getOrders`,
    GET_CLIENT_ACCOUNTS = `getClientAccounts`,
    GET_ACTIVE_ACCOUNT = `getActiveAccount`,
}

export const getCalcQueryKeyById = (id: string) => `${queryKeys.EMISSION_CALCULATION_RESULTS}-${id}`
