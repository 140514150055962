import { useStripe } from '@stripe/react-stripe-js'
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js'
import { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    extractOffsetLinkIdFromPath,
    LinksContext,
    LOCAL_STORAGE_SELECTED_BUNDLE_KEY,
} from 'apps/LinksApp/LinksApp'
import { notify } from 'bugsnag'
import { placeOffsetLinkOrder } from 'endpoints/dapi'
import Confirm from 'viewsOld/ProjectExplorer/Confirm'

const getSelectedBundleIdsFromLocalStorage = () => {
    let selectedBundleIds
    try {
        selectedBundleIds = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_SELECTED_BUNDLE_KEY) || ``,
        )
    } catch {
        console.warn(
            `${LOCAL_STORAGE_SELECTED_BUNDLE_KEY} could not be parsed from localStorage...`,
        )
    }
    return selectedBundleIds
}

const LinksConfirm = () => {
    const stripe = useStripe()
    const { data, selectedBundleIds, setSelectedBundleIds, mainContentWidth } = useContext(
        LinksContext,
    )

    const history = useHistory()
    const { id: offsetLinkId } = useParams<{ id: string }>()

    useEffect(() => {
        if (!selectedBundleIds?.length) {
            const ids = getSelectedBundleIdsFromLocalStorage()

            if (!ids?.length) {
                const home = extractOffsetLinkIdFromPath(window.location.pathname)
                history.push(`/${home}`)
            } else {
                setSelectedBundleIds(ids)
            }
        }
    }, [])

    const errorToast = () => {
        toast(
            `❌ Something went wrong - we couldn't place this order. Please contact support if this happens again.`,
            { type: 'error' },
        )
    }

    const goToSuccessPage = () => {
        history.push(`/thanks`)
    }

    const submitStripePayment = async (paymentSecret: string, card: StripeCardNumberElement) => {
        if (!stripe) {
            throw new Error('Stripe not fully loaded')
        }

        if (!card) {
            throw new Error('Card information not found')
        }

        return stripe.confirmCardPayment(paymentSecret, {
            payment_method: {
                card,
            },
        })
    }

    const submitOffsetLinkOrder = async ({
        email,
        card,
    }: {
        email: string
        card?: StripeCardNumberElement
    }) => {
        try {
            const response = await placeOffsetLinkOrder({
                offsetLinkId,
                email,
                bundleId: selectedBundleIds?.[0]!,
            })

            if (data?.requirePayment === true) {
                const { paymentSecret } = response as { paymentSecret?: string }
                if (!paymentSecret) {
                    // Break hard (bugsnag catches this
                    throw new Error(`paymentSecret is required for offsetLinkId ${data.id}`)
                }
                const result = await submitStripePayment(paymentSecret, card!)
                const isCardError =
                    ((result.error as unknown) as StripeError)?.type === 'card_error'

                if (isCardError) {
                    toast(result.error?.message, { type: 'error' })
                    return
                } else if (result.error) {
                    throw result.error
                }
            }

            goToSuccessPage()
        } catch (err) {
            // What type is err?
            // 1. it is a child of Error or
            // 2. it is an Axios response (not an error: see src/endpoints/util.ts
            // Probably not the best choice of types: burden shifted to run time
            const { status } = err
            if (status === undefined || status >= 500) {
                notify(err)
            }

            errorToast()
        }
    }

    return (
        <Confirm
            data={data!}
            partnerLogoUrl={data?.useLogo && data?.logo}
            selectedBundleIds={selectedBundleIds}
            onSubmit={submitOffsetLinkOrder}
            footerWidth={mainContentWidth}
        />
    )
}

export default LinksConfirm
