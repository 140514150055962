import { ReactComponent as PigtailCurveArrow } from 'images/pig-tail-arrow.svg'
import BigTitle from 'viewsOld/ProjectExplorer/BigTitle/BigTitle'

import styles from './ExploreBundlesDescription.module.css'

const ExploreBundlesDescription = ({
    description,
    title,
}: {
    description?: string
    title?: string
}) => {
    return (
        <div className={styles.Container}>
            <BigTitle title={title || `Remove Carbon`} />
            {description && (
                <div className={styles.Desc}>
                    <div style={{ width: `87%` }}>
                        <pre className={styles.DescPre}>{description}</pre>
                    </div>
                </div>
            )}
            <PigtailCurveArrow className={styles.Arrow} />
        </div>
    )
}

export default ExploreBundlesDescription
