import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { LinksContext, LOCAL_STORAGE_SELECTED_BUNDLE_KEY } from 'apps/LinksApp/LinksApp'
import useQuery from 'hooks/useQuery'
import ProjectDetails from 'viewsOld/ProjectExplorer/ProjectDetails'

const LinksProjectDetails = () => {
    const history = useHistory()
    const { id: offsetLinkId } = useParams<{ id: string }>()
    const { data, setSelectedBundleIds } = useContext(LinksContext)
    const bundleId = useQuery('bundleId')

    return (
        <ProjectDetails
            onSelect={(selected) => {
                setSelectedBundleIds([selected])
                localStorage.setItem(LOCAL_STORAGE_SELECTED_BUNDLE_KEY, JSON.stringify([selected]))
                history.push(`/${offsetLinkId}/confirm`)
            }}
            bundle={data?.bundles?.find((b) => b.id === bundleId)!}
        />
    )
}

export default LinksProjectDetails
