import { Account, AccountPair, Bundle, ClientAccount } from '@lune-climate/lune'
import Check from '@mui/icons-material/Check'
import CreateIcon from '@mui/icons-material/CreateOutlined'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Big } from 'big.js'
import { FORM_ERROR } from 'final-form'
import { Button, CurrencyPicker, LabeledFormSection, LoadingWrapper } from 'lune-ui-lib'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'

import { FormInput } from 'components/FormInput'
import useBundles from 'hooks/useBundles'
import { calculateBundleSelectionTotalSum } from 'utils'
import BundlePicker, {
    bundlePickerView,
    CUSTOM_MIX_ID,
} from 'views/Settings/BundlePicker/BundlePicker'

export enum AccountFormKeys {
    NAME = 'name',
    CURRENCY = 'currency',
    BENEFICIARY = 'beneficiary',
    BUNDLE_SETTINGS = 'bundleSettings',
    SELECTED_BUNDLE_MIX_ID = 'selectedBundleMixId',
    CUSTOM_BUNDLE_SELECTION = 'customBundleSelection',
}

export interface IAccountFormData {
    name: string
    currency: string
    beneficiary?: string
    bundleSettings: {
        customBundleSelection: Record<string, number>
        selectedBundleMixId: string
    }
}

const required = (value: any) => (value?.trim() ? undefined : 'This is a required field')

interface FormModel {
    [AccountFormKeys.NAME]: string
    [AccountFormKeys.CURRENCY]: string
    [AccountFormKeys.BENEFICIARY]?: string
    [AccountFormKeys.BUNDLE_SETTINGS]: {
        [AccountFormKeys.CUSTOM_BUNDLE_SELECTION]: Record<string, number>
        [AccountFormKeys.SELECTED_BUNDLE_MIX_ID]: string
    }
}

const validateSum = (v: any) => {
    const sum = calculateBundleSelectionTotalSum(v.customBundleSelection)
    return sum.eq(Big(100)) ? undefined : `Please submit exactly 100%`
}

const AccountForm = ({
    onSubmit,
    initialValues,
    onSuccess,
    readOnly,
    existingAccount,
    disabledFields,
    bundles,
}: {
    onSubmit: (data: IAccountFormData) => Promise<Account | AccountPair | ClientAccount>
    initialValues: FormModel
    onSuccess: (res?: any) => void
    readOnly?: boolean
    existingAccount?: boolean
    disabledFields?: AccountFormKeys[]
    bundles?: Bundle[]
}) => {
    const { bundlesMixes, loading } = useBundles()
    const [view, setView] = React.useState(bundlePickerView.PERCENTAGES)

    const onSubmitForm = async (values: IAccountFormData) => {
        try {
            const res = await onSubmit({
                name: values.name.trim(),
                beneficiary: values.beneficiary?.trim(),
                currency: values.currency,
                bundleSettings: {
                    customBundleSelection: values.bundleSettings.customBundleSelection,
                    selectedBundleMixId: values.bundleSettings.selectedBundleMixId,
                },
            })
            onSuccess(res)
        } catch (response) {
            const msg = `Something went wrong. Please contact support if this happens again.`
            toast(msg, { type: 'error' })
            return {
                [FORM_ERROR]: msg,
            }
        }
    }

    const FormBundlePicker = React.useCallback(
        ({ input: { onChange, value } }: any) => (
            <BundlePicker
                view={view}
                readOnly={readOnly}
                customBundleSelection={value[AccountFormKeys.CUSTOM_BUNDLE_SELECTION]}
                onChangeCustomBundleSelection={(selection) => {
                    onChange({
                        ...value,
                        [AccountFormKeys.CUSTOM_BUNDLE_SELECTION]: selection,
                    })
                }}
                selectedBundleMixId={value[AccountFormKeys.SELECTED_BUNDLE_MIX_ID]}
                onChangeBundleMix={(selection) => {
                    onChange({
                        ...value,
                        [AccountFormKeys.SELECTED_BUNDLE_MIX_ID]: selection,
                    })
                }}
                allBundles={bundles || []}
                predefinedBundleMixes={bundlesMixes}
            />
        ),
        [view, bundles, bundlesMixes, readOnly],
    )

    return (
        <LoadingWrapper loading={loading}>
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                keepDirtyOnReinitialize
                render={({ handleSubmit, submitting, dirty, valid, values }) => {
                    const isZeroBundlesSelected = !Object.keys(
                        values[AccountFormKeys.BUNDLE_SETTINGS][
                            AccountFormKeys.CUSTOM_BUNDLE_SELECTION
                        ],
                    ).length
                    return (
                        <form style={{ position: `relative`, zIndex: 2 }} onSubmit={handleSubmit}>
                            <Stack
                                direction="column"
                                sx={{
                                    mb: 9,
                                    width: '100%',
                                }}
                                spacing={{
                                    xs: 9,
                                }}
                            >
                                <LabeledFormSection
                                    title={'Name'}
                                    subTile={!readOnly ? 'E.g.EMEA, GBP...' : ''}
                                >
                                    <Field
                                        disabled={
                                            readOnly ||
                                            disabledFields?.includes(AccountFormKeys.NAME)
                                        }
                                        name={'name'}
                                        placeholder={'Name'}
                                        component={FormInput}
                                        validate={required}
                                    />
                                </LabeledFormSection>
                                <LabeledFormSection
                                    title={'Currency'}
                                    subTile={
                                        !existingAccount && !readOnly
                                            ? `You cannot change this later.`
                                            : ``
                                    }
                                >
                                    <Field
                                        name={'currency'}
                                        validate={required}
                                        component={({ input: { onChange, value }, meta }: any) => (
                                            <CurrencyPicker
                                                error={meta.touched && meta.error}
                                                value={value}
                                                disabled={readOnly || existingAccount}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </LabeledFormSection>
                                <LabeledFormSection
                                    title={'Beneficiary'}
                                    subTile={
                                        !readOnly ? (
                                            `Overrides the Organisation’s beneficiary.`
                                        ) : (
                                            <>
                                                <span>
                                                    The beneficiary will be shown on the Carbon
                                                    Offset Certificates.
                                                </span>
                                                <br></br>
                                                <span>
                                                    It will also appear on the carbon registries
                                                    (e.g. Verra).
                                                </span>
                                            </>
                                        )
                                    }
                                >
                                    <Field
                                        disabled={readOnly}
                                        name={'beneficiary'}
                                        placeholder={'Beneficiary'}
                                        component={FormInput}
                                    />
                                </LabeledFormSection>

                                <LabeledFormSection
                                    title={
                                        view === bundlePickerView.SWITCHES
                                            ? `Select project bundles`
                                            : `Default bundle selection`
                                    }
                                    subTile={
                                        view === bundlePickerView.SWITCHES
                                            ? ``
                                            : !readOnly
                                            ? `These settings will apply to any purchase made for this account, unless overridden.`
                                            : `These settings will apply to any purchase made for this Client Account, unless overridden.`
                                    }
                                >
                                    <Field
                                        name={AccountFormKeys.BUNDLE_SETTINGS}
                                        component={FormBundlePicker}
                                        validate={validateSum}
                                    />
                                </LabeledFormSection>

                                {!readOnly && view === bundlePickerView.PERCENTAGES && (
                                    <Box>
                                        <Button
                                            disabled={submitting || !dirty || !valid}
                                            type={'submit'}
                                            leftIcon={<Check />}
                                            sx={{
                                                mr: 1,
                                            }}
                                        >
                                            Save Account
                                        </Button>
                                        {values[AccountFormKeys.BUNDLE_SETTINGS][
                                            AccountFormKeys.SELECTED_BUNDLE_MIX_ID
                                        ] === CUSTOM_MIX_ID && (
                                            <Button
                                                variant={'outlined'}
                                                leftIcon={<CreateIcon />}
                                                onClick={() => setView(bundlePickerView.SWITCHES)}
                                            >
                                                Edit bundles
                                            </Button>
                                        )}
                                    </Box>
                                )}
                                {view === bundlePickerView.SWITCHES && (
                                    <Button
                                        leftIcon={<Check />}
                                        variant={`outlined`}
                                        onClick={() => setView(bundlePickerView.PERCENTAGES)}
                                        disabled={isZeroBundlesSelected}
                                    >
                                        Save bundles
                                    </Button>
                                )}
                            </Stack>
                        </form>
                    )
                }}
            />
        </LoadingWrapper>
    )
}

export default AccountForm
