import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from 'apps/CalculatorApp/CalculatorBackButton/CalculatorBackButton.module.css'

const CalculatorBackButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className={styles.CalculatorBackButton} onClick={onClick}>
            <FontAwesomeIcon className={styles.Chev} icon={faChevronLeft} />
        </div>
    )
}

export default CalculatorBackButton
