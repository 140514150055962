import { Bundle } from '@lune-climate/lune'
import React from 'react'

import { BUNDLE_MAX_SIZE } from 'viewsOld/OffsetLinksForm'
import BundleListItem from 'viewsOld/ProjectExplorer/Bundles/BundleListItem'

import styles from './Bundles.module.css'

const Bundles = ({
    bundles,
    selectedBundleIds,
    onSelect,
    onExplore,
}: {
    bundles: Bundle[]
    selectedBundleIds?: string[]
    onSelect?: (id: string) => void
    onExplore?: (id: string) => void
}) => {
    const numBundles = bundles?.length ?? 0
    const layoutClass =
        numBundles <= 3
            ? ''
            : numBundles <= 6
            ? styles.GridTwoRowsLayout
            : numBundles <= 15
            ? styles.GridThreeRowsLayout
            : styles.GridFourRowsLayout

    return (
        <div className={styles.Container}>
            <div className={`${styles.Grid} ${layoutClass}`}>
                {bundles?.slice(0, BUNDLE_MAX_SIZE)?.map((b) => (
                    <span key={b.id}>
                        <BundleListItem
                            active={selectedBundleIds.includes(b.id)}
                            onSelect={() => {
                                onSelect(b.id)
                            }}
                            onExplore={() => {
                                onExplore(b.id)
                            }}
                            imageUrl={b?.primaryImage || ``}
                            name={b.name}
                        />
                    </span>
                ))}
                {
                    // Empty1PXGridCell
                    // Empty grid cell - alignment workaround for mobile view grid alignment
                }
                <div id={`Empty1PXGridCell`} className={styles.Empty1PXGridCell} />
            </div>
        </div>
    )
}

export default Bundles
