import Box from '@mui/material/Box'
import { ButtonGroup, DateRangePicker, Dropdown } from 'lune-ui-lib'
import moment from 'moment'
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'

export enum PredefinedRange {
    ALL_TIME = 'allTime',
    THREE_MONTHS = '3',
    TWELVE_MONTHS = '12',
    CUSTOM = 'custom',
}

const DATE_FORMAT = 'DD MMM YYYY'

const DateRangeFilter: FC<{
    dateRange: Range
    setDateRange: Dispatch<SetStateAction<Range>>
}> = ({ dateRange, setDateRange }) => {
    const [predefinedRange, setPredefinedRange] = useState<string>(PredefinedRange.TWELVE_MONTHS)
    const todayUTC = useMemo(() => moment().utc(), [])

    const predefinedRanges = [
        {
            label: 'Last 3 months',
            value: PredefinedRange.THREE_MONTHS,
        },
        {
            label: 'Last 12 months',
            value: PredefinedRange.TWELVE_MONTHS,
        },
        {
            label: 'All time',
            value: PredefinedRange.ALL_TIME,
        },
    ]

    const getSelectionLabel = useMemo(() => {
        const selectedPredefinedRange = predefinedRanges.find(
            (range) => range.value === predefinedRange,
        )
        if (!selectedPredefinedRange) {
            // TODO @FESTINA change to => 10 – 20 May 2022, 10 May – 20 Jun 2022, 10 May 2021 – 20 Jun 2022
            return `${moment(dateRange.startDate).format(DATE_FORMAT)} - ${moment(
                dateRange.endDate,
            ).format(DATE_FORMAT)}`
        } else {
            return selectedPredefinedRange.label
        }
    }, [dateRange])

    useEffect(() => {
        if (predefinedRange === PredefinedRange.ALL_TIME) {
            setDateRange({
                startDate: undefined,
                endDate: undefined,
            })
        }
        if (
            predefinedRange !== PredefinedRange.CUSTOM &&
            predefinedRange !== PredefinedRange.ALL_TIME
        ) {
            setDateRange({
                startDate: todayUTC.clone().subtract(predefinedRange, `months`).toDate(),
                endDate: todayUTC.toDate(),
            })
        }
    }, [predefinedRange])

    return (
        <Dropdown buttonLabel={getSelectionLabel} closeOnClick={false}>
            <Box sx={{ padding: '16px 24px' }}>
                <ButtonGroup
                    sx={{
                        height: '54px !important',
                        width: '100%',
                        overflow: 'hidden',
                        mb: 4,
                    }}
                    items={predefinedRanges}
                    onChange={(predefinedRange) => setPredefinedRange(predefinedRange)}
                    value={predefinedRange}
                />
                <DateRangePicker
                    onChange={(range) => {
                        if (range.startDate?.getDate() !== range.endDate?.getDate()) {
                            setPredefinedRange(PredefinedRange.CUSTOM)
                            setDateRange({
                                startDate: range.startDate,
                                endDate: range.endDate,
                            })
                        }
                    }}
                    predefinedRange={{
                        startDate: dateRange.startDate!,
                        endDate: dateRange.endDate!,
                    }}
                />
            </Box>
        </Dropdown>
    )
}

export default DateRangeFilter
