import Container from '@mui/material/Container'
import { experimental_sx as sx, styled } from '@mui/system'
import { Button, ListItemLayout, Loading, LuneTheme } from 'lune-ui-lib'
import { useSnackbar } from 'notistack'
import React, { FC, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { uploadOrganisationLogo } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useUserState, { useIsAuthorised } from 'hooks/useUserState'
import { IAccount } from 'models/account'
import { SnackbarMessages } from 'SnackbarMessages'

const Image = styled('img')(
    sx({
        width: `48px`,
        height: `48px`,
        objectFit: 'cover',
        borderRadius: '50%',
    }),
)

const ManageLogo: FC = () => {
    const isAuthorised = useIsAuthorised()
    const [uploading, setUploading] = useState(false)
    const { userState, setUserState } = useUserState()
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { palette } = LuneTheme

    const onUploadLogo = (acceptedFiles: File[]) => {
        const formData = new FormData()
        const profilePic = acceptedFiles[0]
        formData.append('logo', profilePic)
        setUploading(true)
        uploadOrganisationLogo(activeOrg?.id!, formData)
            .then((res) => {
                if (userState?.account) {
                    const updatedAccount = {
                        ...userState.account,
                        logo: res.data.url,
                    } as IAccount
                    setUserState({ ...userState!, account: updatedAccount })
                }
                snackbar(SnackbarMessages.ORGANISATION_LOGO_UPLOAD_SUCCESS)
                return refetchActiveOrg()
            })
            .catch(() => {
                snackbar(SnackbarMessages.ORGANISATION_LOGO_UPLOAD_FAIL)
            })
            .finally(() => setUploading(false))
    }

    const Placeholder = useMemo(
        () =>
            styled('div')(
                sx({
                    width: `48px`,
                    height: `48px`,
                    backgroundColor: palette.Grey100,
                    borderRadius: '50px',
                }),
            ),
        [],
    )

    const { getRootProps, getInputProps } = useDropzone({ onDrop: onUploadLogo })

    const uploadImage = useMemo(() => {
        if (uploading) {
            return <Loading />
        }

        if (activeOrg?.logo) {
            return <Image src={activeOrg.logo} />
        }

        return <Placeholder />
    }, [uploading, activeOrg])

    return (
        <Container
            disableGutters
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <ListItemLayout
                image={uploadImage}
                title={`Company logo`}
                subTitle={`JPG, JPEG or PNG up to 200kb`}
            />
            <div {...getRootProps()}>
                {isAuthorised && (
                    <Button variant={'text'} onClick={() => {}}>
                        Upload image
                    </Button>
                )}
                <input
                    {...getInputProps()}
                    data-testid={'drop-input'}
                    style={{
                        display: `none`,
                    }}
                />
            </div>
        </Container>
    )
}

export default ManageLogo
