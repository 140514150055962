import { Component } from 'react'

import SVGLuneLogo from 'componentsOld/SVGLogo'
import PartnerLogo from 'viewsOld/ProjectExplorer/PartnerLogo'

import styles from './Footer.module.css'

class Footer extends Component<{
    width?: number | undefined
    partnerLogoUrl?: string | false
    className?: string
}> {
    render() {
        return (
            <div className={`${styles.FooterOuter} ${this.props.className || undefined}`}>
                <div
                    className={styles.Footer}
                    style={{ width: this?.props?.width ? `${this?.props?.width}px` : `80%` }}
                >
                    <div className={styles.PartnerLogo}>
                        {this?.props?.partnerLogoUrl && (
                            <PartnerLogo url={this?.props?.partnerLogoUrl} />
                        )}
                    </div>
                    <div className={styles.PoweredBy}>
                        Powered by <SVGLuneLogo className={styles.LuneLogo} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
