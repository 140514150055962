import { Bundle } from '@lune-climate/lune'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import MobileBackButton from 'componentsOld/BackButton'
import ProjectTypeTag from 'componentsOld/ProjectTypeTag'
import useQuery from 'hooks/useQuery'
import AddBundleRibbon from 'viewsOld/ProjectExplorer/AddBundleRibbon'
import BundleDesktopLeftPanel from 'viewsOld/ProjectExplorer/BundleDesktopLeftPanel'
import BundleMobileAddRibbonWrapper from 'viewsOld/ProjectExplorer/BundleMobileAddRibbonWrapper'
import IndividualProjectMap from 'viewsOld/ProjectExplorer/ProjectDetails/IndividualProjectMap'

import styles from './ProjectDetails.module.css'

const ProjectDetails = ({
    bundle,
    onSelect,
}: {
    bundle: Bundle
    onSelect: (bundleId: string) => void
}) => {
    const { id: offsetLinkId, projectId } = useParams<{ id: string; projectId: string }>()
    const history = useHistory()
    const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 100%), url(${bundle?.primaryImage})`
    const bundleId = useQuery('bundleId')

    const project = bundle.projects.find((p) => p.id === projectId)

    const onBackClick = () =>
        history.push({
            pathname: `/${offsetLinkId}/explore/List`,
            search: `?bundleId=${bundleId}`,
        })

    const addBundleRibbonComp = (
        <AddBundleRibbon bundleName={bundle.name} bundleId={bundle.id} onSelect={onSelect} />
    )

    return (
        <div className={styles.Container}>
            <BundleDesktopLeftPanel backgroundImage={backgroundImage} onBack={onBackClick}>
                {addBundleRibbonComp}
            </BundleDesktopLeftPanel>
            <div className={styles.RightContainer}>
                <div className={styles.RightContainerOuter}>
                    <div className={styles.RightContainerInner}>
                        <MobileBackButton
                            onClick={onBackClick}
                            className={styles.MobileBackButton}
                        />
                        <div className={styles.CountryName}>{project?.countryName}</div>
                        <div className={styles.ProjectName}>{project?.name}</div>
                        <ProjectTypeTag
                            className={styles.Tags}
                            projectType={project?.projectType!}
                        />
                        <div style={{ display: `flex`, justifyContent: `center` }}>
                            <div
                                className={styles.CoverImage}
                                style={{ backgroundImage: `url(${project?.primaryImage})` }}
                            />
                        </div>
                        <pre className={styles.Description}>{project?.description}</pre>
                        <div className={styles.SectionTitle}>Results:</div>
                        <div style={{ marginBottom: `16px` }}>
                            {project?.results?.map((r, index) => (
                                <div className={styles.ResultTile} key={`res-${index}`}>
                                    {r}
                                </div>
                            ))}
                        </div>
                        <div className={styles.SectionTitle}>Location:</div>
                        <div className={styles.MapWrapper}>
                            <IndividualProjectMap project={project!} />
                        </div>
                        <BundleMobileAddRibbonWrapper
                            className={styles.NoPadding}
                            backgroundImage={backgroundImage}
                        >
                            {addBundleRibbonComp}
                        </BundleMobileAddRibbonWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails
